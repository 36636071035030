import React, { useState } from 'react';
import { FaBook, FaProjectDiagram } from "react-icons/fa";
import {
  MdEmojiPeople,
  MdFavoriteBorder,
  MdGroups,
  MdOutlineDashboard,
  MdOutlineExplore,
  MdOutlineOndemandVideo,
  MdOutlineVideoCameraFront,
  MdWorkspacePremium,
} from "react-icons/md";
import { RiGitRepositoryCommitsLine } from "react-icons/ri";
import { FaRegFileLines } from "react-icons/fa6";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { LiaCertificateSolid } from "react-icons/lia";
import Cookies from "js-cookie";
import Tooltip from "@mui/material/Tooltip";
import axios from 'axios';
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
// console.log(fetchPlanName(), "from rouets.js");

let userInfo = null;
let userrole = null;
let planname = null;
let companyemail = null

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
    // planname = userInfo?.planname;
    companyemail = userInfo?.companyemail

    // Get planname from locak storage
    planname = localStorage.getItem("planname")
    console.log(planname, "planname");
    console.log(userrole, "user role")
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}



const premiumIcon = (
  <Tooltip title="Kindly purchase the premium plan" sx={{height:"5px"}}>
    <span>
      <MdWorkspacePremium style={{ cursor: "not-allowed", }} />
    </span>
  </Tooltip>
);

export const routes1 = [
  {
    path: "/kms",
    name: "KMS",
    iconComponent: <FaBook />,
   
  },
  {
    path: "/lms",
    name: "LMS",
    iconComponent: <MdOutlineOndemandVideo />,
   
  },
  {
    path: "/hrms",
    name: "Hrm",
    iconComponent: <MdGroups />,
   
  },

  {
    path: "/pms/summary",
    name: "PMS",
    iconComponent: <FaProjectDiagram/> ,
    subRoutes: [
      {
        path: "/pms/summary",
        name: "Summary",
        iconComponent: <MdOutlineDashboard />,
      },
      {
        path: "/pms/reports",
        name: "Reports",
        iconComponent: <MdOutlineDashboard />,
      },
      {
        path: "/pms/calendar",
        name: "Calendar",
        iconComponent: <MdOutlineDashboard />,
      },
      {
        path: "/pms/discuss",
        name: "Discuss",
        iconComponent: <MdOutlineDashboard />,
      },
      {
        path: "/pms/projects",
        name: "Projects",
        iconComponent: <MdEmojiPeople />,
      },
      // {
      //   path: "/pms/billing",
      //   name: "Setting",
      //   iconComponent: <SettingsIcon />,
     
      // }
  
    ],
  },
  {
    path: "/dms",
    name: "DMS",
    iconComponent: <FaBook />,

  },
  {
    path: "/settings/billing",
    name: "Settings",
    iconComponent: <SettingsIcon />,
    subRoutes: [
      {
        path: "/settings/billing",
        name: "Billing",
        iconComponent: <SpeedIcon />,
      
      },
    ],
  }
];

// Add or remove premium icons based on the planname
if (planname === "Basic") {
  routes1.forEach(route => {
    if (route.path === "/kms/dashboard") {
      route.subRoutes =[]
    } else if (route.path === "/lms/dashboard") {
      route.subRoutes =[]
    }
  });
} else if (planname === "Standard") {
  routes1.forEach(route => {
    if (route.path === "/lms/dashboard") {
      route.subRoutes =[]
    }
  });
} else if (planname === "Premium") {
  routes1.forEach(route => {
    if (route.path === "/kms/dashboard" || route.path === "/lms/dashboard") {
      // Remove premium icons
      route.iconComponent = <MdOutlineOndemandVideo />;
    }
  });
}



export const otherroutes = [];
