// File: /path_to_context/MyContext.js
import React, { createContext, useState } from "react";

export const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [taskData, setTaskData] = useState(null);

  return (
    <MyContext.Provider value={{ taskData, setTaskData }}>
      {children}
    </MyContext.Provider>
  );
};
