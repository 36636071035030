// File: /path_to_component/Repository.js

import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import ActiveProjects from "../ActiveProjects/ActiveProjects";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function ProjectsTab() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const role = userInfo.userrole;
  const userid = userInfo.id;

  const [selectedTab, setSelectedTab] = useState(0);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleProjectChange = (event) => {
    const projectname = event.target.value;
    const selectedProjectObj = projects.find(
      (project) => project.projectname === projectname
    );
    if (selectedProjectObj) {
      const projectid = selectedProjectObj.id;
      navigate(`/pms/projects/${projectname}/${projectid}`);
    }
  };

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/projects/get`, { companyid, role, userid })
      .then((res) => {
        setProjects(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ display: "flex", py: 0.5, px: 2 }}
        className="common-background-color"
      >
        <Tabs value={selectedTab} onChange={handleChange} sx={{ gap: 2 }}>
          <Tab
            label="Active Projects"
            sx={{
              textTransform: "capitalize",
              color: selectedTab === 0 ? "#0B4A6F !important" : "#98A2B3",
              fontSize: selectedTab === 0 ? "1rem" : "0.8rem",
              backgroundColor: "#FBFBFB",
              borderRadius: "8px",
              border: "1px solid rgba(166, 209, 247,1)",
              padding: "4px 10px",
              minWidth: "155px",
              textAlign: "center",
              transition: "all 0.3s ease-in-out",
            }}
          />
        </Tabs>

        <select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{
            marginLeft: 16,
            backgroundColor: "#FBFBFB",
            border: "1px solid #F2F4F7",
            borderRadius: "8px",
            color: "#98A2B3",
            padding: "8px",
            fontSize: "16px",
          }}
        >
          <option value="" disabled>
            Select a Project
          </option>
          {projects.map((item, index) => (
            <option key={index} value={item.projectname}>
              {item.projectname}
            </option>
          ))}
        </select>
      </Box>

      <Box sx={{ padding: 2 }}>{selectedTab === 0 && <ActiveProjects />}</Box>
    </Box>
  );
}

export default ProjectsTab;
