import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  Select as MUISelect,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { fileSizeManager } from "../../../../../Constrant/fileSizeManager";
import ReactQuill from "react-quill";

export default function AddBillings({ onClose, refresh, setRefresh }) {
  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid;

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const userid = userInfo.id;
  const useremail = userInfo.email;

  const [data, setData] = useState({
    empid: null,
    empname: "",
    projectid: projectId,
    reportingmanager: "",
    department: "",
    position: "",
    ctc: null,
    billingperiod: "",
    companyid,
    companyemail,
    userid,
  });

  const [employeeData, setEmployeeData] = useState([]);
  const [employeeValue, setEmployeeValue] = useState("");

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/billing/getemployee`, { companyid })
      .then((res) => {
        setEmployeeData(res.data);

        // console.log(res.data, "employee data");
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const res = await axios.post("https://pms.vliv.app/billing/add", data);

      if (res.data.message == "Billing added successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error saving task:", error);
      alert("Failed to save billing");
    }
  };

  const handleEmployeeInputChange = (e) => {
    setEmployeeValue(e.target.value);

    setData((prevData) => ({
      ...prevData,
      empid: e.target.value.id,
      reportingmanager: e.target.value.reportmanager,
      department: e.target.value.department,
      position: e.target.value.position,
      ctc: e.target.value.ctc,
    }));
  };

  return (
    <Box sx={{ width: 600 }}>
      <Typography variant="h6" sx={{ padding: 0.5, ml: 2 }}>
        New Billing
      </Typography>
      <Divider />
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <label>Project Name</label>
          <TextField
            name="projectname"
            value={projectName}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <label>Employee ID</label>
          <TextField
            name="empid"
            value={data.empid}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <label>Employee Name*</label>
          <FormControl fullWidth>
            <MUISelect
              value={employeeValue}
              onChange={handleEmployeeInputChange}
            >
              <MenuItem>Select</MenuItem>
              {employeeData.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <label>Reporting manager</label>
          <TextField
            name="reportingmanager"
            value={data.reportingmanager}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <label>Department</label>
          <TextField
            name="department"
            value={data.department}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label>Position</label>
          <TextField
            name="position"
            value={data.position}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label>CTC</label>
          <TextField
            name="ctc"
            value={data.ctc}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <label>Billing period</label>
          <TextField
            name="billingperiod"
            value={data.billingperiod}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
          padding: 2.5,
        }}
      >
        <Button variant="contained" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#37668F" }}
          onClick={handleSave}
        >
          Create
        </Button>
      </Box>
    </Box>
  );
}
