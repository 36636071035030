 
 import axios from 'axios';

 export const fileSizeManager = async(fileSizeInGB, companyid, companyemail)=>{
// console.log(fileSizeInGB, companyid, companyemail, "size, companyid, compemail")

    axios
    .post(`https://pms.vliv.app/storage/addsize`, {
      size: fileSizeInGB,
      companyid,
      companyemail,
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => console.log(err));

}

