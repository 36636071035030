// helper.js
export function transformApiDataToGanttTasks(apiData) {
  return apiData.map((item) => {
    // Check if startdate and enddate are valid, otherwise provide a fallback date
    const start = item.startdate ? new Date(item.startdate) : new Date();
    const end = item.enddate ? new Date(item.enddate) : new Date();

    // Map tasks to each owner in the "owner" array
    return item.owner.map((owner) => ({
      id: item.id.toString(),
      name: item.task || "Untitled Task",
      start: isNaN(start.getTime()) ? new Date() : start, // Check if start is valid
      end: isNaN(end.getTime()) ? new Date() : end, // Check if end is valid
      type: "task",
      progress: 0, // Adjust as needed
      project: item.projectid ? item.projectid.toString() : null,
      createdby: owner, // Use each owner name as "createdby"
    }));
  }).flat(); // Flatten the array as we are returning tasks for each owner
}
