import React, { useState, useEffect } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import {
  transformApiDataToGanttTasks,
  getStartEndDateForProject,
} from "./helper";
import axios from "axios";
import { ViewSwitcher } from "./ViewSwitcher";
import "gantt-task-react/dist/index.css";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import "./GanttChart.css";

const GanttComponent = () => {
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  let columnWidth = 30;

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const url = useParams();
  const projectName = url.projectname;

  useEffect(() => {
    // Simulating an API call to fetch tasks
    axios
      .post(`https://pms.vliv.app/task/get`, {
        companyid,
        projectname: projectName,
      })
      .then((response) => {
        const taskData = response.data;
        const transformedTasks = transformApiDataToGanttTasks(taskData);
        setTasks(transformedTasks);
        setLoading(false);
      });
  }, []);

  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));

    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks.find((t) => t.id === task.project);
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const updatedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? updatedProject : t
        );
      }
    }

    setTasks(newTasks);
  };

  const handleExpanderClick = (task) => {
    setTasks(
      tasks.map((t) =>
        t.id === task.id ? { ...t, hideChildren: !t.hideChildren } : t
      )
    );
  };

  if (loading) {
    return <p>Loading tasks...</p>;
  }

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      <Gantt
        tasks={tasks}
        viewMode={view}
        onDateChange={handleTaskChange}
        onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "155px" : ""}
        columnWidth={columnWidth}
        barBackgroundColor="blue"
        rowHeight={40}
        fontSize={12}
      />
    </div>
  );
};

export default GanttComponent;
