import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
  Popover,
  IconButton,
  Drawer,
  FormControl,
  InputAdornment,
  Avatar,
} from "@mui/material";
import Cookies from "js-cookie";
import { MdDeleteSweep } from "react-icons/md";
import { FaExclamation, FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { CiSearch } from "react-icons/ci";

import AddBillings from "./AddBillings";
import UpdateBillings from "./UpdateBillings";
import { generateAvatarColor, getInitials } from "../../../../../Constrant/constants";

export default function Billings({ refresh, setRefresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userid = userInfo.id;

  const url = useParams();
  const projectName = url.projectname;
  const projectid = url.projectid;
  console.log(projectid, companyid)

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([
  // {
  //   id:1,
  //   "empid":435,
  //   empname:"Aniket babariya",
  //   "projectid": 1,
  //   "reportingmanager": "John Doe",
  //   "department": "Engineering",
  //   "position": "Software Engineer",
  //   "ctc": 75000,
  //   "billingperiod": "2024-01-01 to 2024-01-31",
  //   "companyid": 91,
  //   "companyemail": "john.doe@company.com",
  //   "userid": 113
  // },
  // {
  //   id:2,
  //   "empid":436,
  //   empname:"Ranjan Patel", 
  //   "projectid": 1,
  //   "reportingmanager": "John Doe",
  //   "department": "Engineering",
  //   "position": "Software Engineer",
  //   "ctc": 75000,
  //   "billingperiod": "2024-01-01 to 2024-01-31",
  //   "companyid": 91,
  //   "companyemail": "john.doe@company.com",
  //   "userid": 113
  // }
  ]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerUpdateOpen, setDrawerUpdateOpen] = useState(false);

  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/billing/get`, {
        companyid,
        projectid: projectid,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        setLoading(false);
        console.log(res.data, "get all billing");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/billing/delete`, {
          ids: selectionModel,
        })
        .then(() => {
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerUpdateOpen(true);
    }
  };

  const handleClose = () => {
    setDrawerUpdateOpen(false);
    setDrawerOpen(false);
    setRowDataForEdit(null);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateBill = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const openPopover = Boolean(anchorEl);

  const columns = [
    {
      field: "empid",
      // minWidth: 500,
      headerName: "Employee ID",
      flex: 1,
      sortable: true,
    },

    {
      field: "empname",
      headerName: "Employee name",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        const firstOwner = params.row.Employee.name  || "";
       
        // const additionalOwnersCount = owners.length - 1;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Placeholder avatar with the first letter of the owner's name */}
            <Avatar
              sx={{
                bgcolor: generateAvatarColor(firstOwner),
                fontSize: "10px",
                height: "28px",
                width: "28px",
                marginRight: "3px",
              }}
            >
              {getInitials(firstOwner)}
            </Avatar>
            {/* Owner's name */}
            <Box>{firstOwner}</Box>
          
          </div>
        );
      },
    },
    {
      field: "reportingmanager",
      headerName: "reportingmanager",
      flex: 0.5,
      minWidth: 10,
      sortable: true,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      sortable: true,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      sortable: true,
    },
    {
      field: "ctc",
      headerName: "CTC",
      flex: 0.5,
      sortable: true,
    },
    {
      field: "billingperiod",
      headerName: "Billing Period",
      minWidth: 250,
      flex: 0.5,
      sortable: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    }
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  return (
    <>
      <CssBaseline />
      <Typography variant="h6" gutterBottom>
        Billing
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          sx={{ background: "#37668F" }}
          onClick={handleCreateBill}
        >
          Add Bill
        </Button>

        <FormControl>
          <TextField
            size="small"
            variant="outlined"
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CiSearch />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {/* 
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ marginBottom: 2, width: 400 }}
          onChange={(e) => setSearchValue(e.target.value)}
        /> */}
        {selectionModel.length > 0 && (
          <MdDeleteSweep
            style={{ width: "25px", height: "25px", color: "red" }}
            onClick={handleDelete}
          />
        )}
      </Box>

      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
        />
      </div>

      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <AddBillings
          open={drawerOpen}
          onClose={handleClose}
          billingData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>
      <Drawer anchor="right" open={drawerUpdateOpen} onClose={handleClose}>
        <UpdateBillings
          open={drawerUpdateOpen}
          onClose={handleClose}
          billingData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>
    </>
  );
}
