// /src/Charts.js
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import { Tooltip as MUItoolTip, Avatar } from "@mui/material";

import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import TaskStatusBar from "./SecondCharts/TaskStatusBar";
import { AiOutlinePieChart } from "react-icons/ai";
import { IoBarChartSharp } from "react-icons/io5";
import MilestoneStatusBar from "./SecondCharts/MilestoneStatusBar";
import TimesheetBar from "./SecondCharts/TimesheetBar";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const Charts = () => {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userid = userInfo.id;
  const userrole = userInfo.userrole;
  const username = userInfo.username;

  const { projectname, projectid } = useParams();
  // Show different different chart based on the button click
  const [isShowTaskPie, setIsShowTaskPie] = useState(true);
  const [isShowMilestonePie, setIsShowMilestonePie] = useState(true);
  const [isShowTimesheetPie, setIsShowTimesheetPie] = useState(true);

  const [milestones, setMilestones] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [tags, setTags] = useState({});
  const [overdueCount, setOverdueCount] = useState({ tasks: 0, milestones: 0 });
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState("W1"); // Default week selection for Weekly Report chart only
  const [filteredSubtasks, setFilteredSubtasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredMilestones, setFilteredMilestones] = useState([]);
  const [timesheetData, setTimesheetData] = useState({
    billable: 0,
    nonBillable: 0,
  });

  const statusColors = {
    "In Progress": "#2ecc71",
    Closed: "#e74c3c",
    "To be Tested": "#f39c12",
    Open: "#3498db",
    "On Hold": "#9b59b6",
  };

  // Count status occurrences for both tasks and milestones
  const countStatusOccurrences = (items) => {
    return items.reduce((acc, item) => {
      const status = item.status || "Unknown";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [milestoneRes, taskRes] = await Promise.all([
          axios.post("https://pms.vliv.app/milestone/get", {
            companyid,
            projectname,
          }),
          axios.post("https://pms.vliv.app/task/get", {
            companyid,
            projectname,
          }),
        ]);

        const milestoneData = milestoneRes.data || [];
        const taskData = taskRes.data || [];

        setMilestones(milestoneData);
        setTasks(taskData);
        setLoading(false);

        calculateTagsAndOverdue(taskData, milestoneData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      axios
        .post(`https://pms.vliv.app/subtask/getfordashboard`, {
          companyid,
          userrole,
          userid,
          owner: username,
        })
        .then((res) => {
          setSubtasks(res.data.length > 0 ? res.data : []);
        })
        .catch((err) => console.log(err));

      axios
        .post(`https://pms.vliv.app/timesheet/getall`, {
          companyid,
          projectid,
          userid,
        })
        .then((res) => {
          const timesheetData = res.data || [];
          setTimesheetData(calculateBillableNonBillable(timesheetData));
        })
        .catch((err) => console.log(err));
    };

    fetchData();
  }, [companyid, projectname]);

  // ================= For Task status data start ===========
  // Dynamic Pie Chart Data for Tasks
  const taskStatusCounts = countStatusOccurrences(tasks);
  const taskPieData = {
    labels: Object.keys(taskStatusCounts),
    datasets: [
      {
        data: Object.values(taskStatusCounts),
        backgroundColor: Object.keys(taskStatusCounts).map(
          (status) => statusColors[status] || "#e74c3c" // Default color
        ),
      },
    ],
  };
  // Dynamic bart chart data for tasks
  const taskBarData = {
    labels: Object.keys(taskStatusCounts), // Same labels as Pie chart
    datasets: [
      {
        label: "Task", // Label for the Bar chart
        data: Object.values(taskStatusCounts), // Data from taskStatusCounts
        backgroundColor: Object.keys(taskStatusCounts).map(
          (status) => statusColors[status] || "#e74c3c" // Same color logic
        ),
        barThickness: 50, // Adjust the thickness of the bars
      },
    ],
  };
  // ================= For Task status data End ===========

  // ================= For Milestone status data start ===========
  // Dynamic Pie Chart Data for Milestones
  const milestoneStatusCounts = countStatusOccurrences(milestones);
  const milestonePieData = {
    labels: Object.keys(milestoneStatusCounts),
    datasets: [
      {
        data: Object.values(milestoneStatusCounts),
        backgroundColor: Object.keys(milestoneStatusCounts).map(
          (status) => statusColors[status] || "#e74c3c" // Default color
        ),
      },
    ],
  };

  // Dynamic bart chart data for milestone
  const milestoneBarData = {
    labels: Object.keys(milestoneStatusCounts), // Same labels as Pie chart
    datasets: [
      {
        label: "Milestone", // Label for the Bar chart
        data: Object.values(milestoneStatusCounts), // Data from milestoneStatusCounts
        backgroundColor: Object.keys(milestoneStatusCounts).map(
          (status) => statusColors[status] || "#e74c3c" // Same color logic
        ),
        barThickness: 50, // Adjust the thickness of the bars
      },
    ],
  };
  // ================= For Milestone status data end ===========

  useEffect(() => {
    filterDataByWeek(selectedWeek);
  }, [selectedWeek, tasks, subtasks, milestones]);

  const filterDataByWeek = (week) => {
    const weekRanges = getWeekRanges();
    const startOfWeek = weekRanges[week].start;
    const endOfWeek = weekRanges[week].end;

    const filteredTasks = tasks.filter((task) =>
      dayjs(task.createdAt).isBetween(startOfWeek, endOfWeek, null, "[]")
    );
    const filteredSubtasks = subtasks.filter((subtask) =>
      dayjs(subtask.createdAt).isBetween(startOfWeek, endOfWeek, null, "[]")
    );
    const filteredMilestones = milestones.filter((milestone) =>
      dayjs(milestone.createdAt).isBetween(startOfWeek, endOfWeek, null, "[]")
    );

    setFilteredTasks(filteredTasks);
    setFilteredSubtasks(filteredSubtasks);
    setFilteredMilestones(filteredMilestones);
  };

  const getWeekRanges = () => {
    const now = dayjs();
    const firstDayOfMonth = now.startOf("month");
    const ranges = {
      W1: { start: firstDayOfMonth, end: firstDayOfMonth.add(6, "day") },
      W2: {
        start: firstDayOfMonth.add(7, "day"),
        end: firstDayOfMonth.add(13, "day"),
      },
      W3: {
        start: firstDayOfMonth.add(14, "day"),
        end: firstDayOfMonth.add(20, "day"),
      },
      W4: {
        start: firstDayOfMonth.add(21, "day"),
        end: firstDayOfMonth.endOf("month"),
      },
    };
    return ranges;
  };

  const calculateTagsAndOverdue = (taskData, milestoneData) => {
    const tagCounter = {};
    let overdueTasks = 0,
      overdueMilestones = 0;

    taskData.forEach((task) => {
      if (task.overdue) overdueTasks++;
      task.tag.forEach((tag) => {
        tagCounter[tag] = (tagCounter[tag] || 0) + 1;
      });
    });

    milestoneData.forEach((milestone) => {
      if (milestone.overdue) overdueMilestones++;
    });

    setTags(tagCounter);
    setOverdueCount({ tasks: overdueTasks, milestones: overdueMilestones });
  };

  const weeklyReportData = {
    labels: ["Task", "Sub-Tasks", "Milestones"],
    datasets: [
      {
        label: "Closed",
        backgroundColor: "#e74c3c",
        data: [
          filteredTasks.filter((task) => task.status === "Closed").length,
          filteredSubtasks.filter((subtask) => subtask.status === "Closed")
            .length,
          filteredMilestones.filter(
            (milestone) => milestone.status === "Closed"
          ).length,
        ],
      },
      {
        label: "In Progress",
        backgroundColor: "#2ecc71",
        data: [
          filteredTasks.filter((task) => task.status === "In Progress").length,
          filteredSubtasks.filter((subtask) => subtask.status === "In Progress")
            .length,
          filteredMilestones.filter(
            (milestone) => milestone.status === "In Progress"
          ).length,
        ],
      },
      {
        label: "Open",
        backgroundColor: "#3498db",
        data: [
          filteredTasks.filter((task) => task.status === "Open").length,
          filteredSubtasks.filter((subtask) => subtask.status === "Open")
            .length,
          filteredMilestones.filter((milestone) => milestone.status === "Open")
            .length,
        ],
      },
    ],
  };

  // Timesheet billable calculate function
  const calculateBillableNonBillable = (timesheetEntries) => {
    let billableCount = 0;
    let nonBillableCount = 0;

    timesheetEntries.forEach((entry) => {
      if (entry.billable === "Yes") {
        billableCount++;
      } else if (entry.billable === "No") {
        nonBillableCount++;
      }
    });

    return { billable: billableCount, nonBillable: nonBillableCount };
  };

  // Timesheet Pie Chart Data for Billable vs Non-Billable
  const timesheetPieData = {
    labels: ["Billable", "Non-Billable"],
    datasets: [
      {
        data: [timesheetData.billable, timesheetData.nonBillable],
        backgroundColor: ["#f39c12", "#e74c3c"],
      },
    ],
  };

  // For timesheet bar chart
  const timesheetBarData = {
    labels: ["Billable", "Non-Billable"],
    datasets: [
      {
        label: "Count", // Label for the Bar chart
        data: [timesheetData.billable, timesheetData.nonBillable],
        backgroundColor: ["#f39c12", "#e74c3c"],
        barThickness: 50,
      },
    ],
  };

  // for overdue

  const getUserInitials = (name) => {
    const nameParts = name.split(" ");
    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  };

  const generateRandomColor = () => {
    const colors = ["#3498db", "#e74c3c", "#2ecc71", "#9b59b6", "#f39c12"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const overdueItems = tasks.map((task) => ({
    user: task.username,
    task: task.task,
    overdue: task.overdue ? 1 : 0, // Example based on task data
    today: 0, // Update logic for today's tasks if needed
    allOpen: task.status === "Open" ? 1 : 0,
  }));

  if (loading)
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container spacing={2} sx={{ background: "#f4fafe" }}>
      {/* Row 1: Pie Charts */}
      <Grid item xs={12} md={6}>
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              background: "#E0F2FE",
              padding: 2,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {" "}
              <Typography variant="h6">Task Status</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1.5 }}>
              <Typography
                sx={{
                  background: isShowTaskPie ? "rgba(55, 102, 143, 1)" : "#fff",
                  color: isShowTaskPie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowTaskPie(true)}
              >
                <AiOutlinePieChart style={{ width: "20px", height: "20px" }} />
              </Typography>
              <Typography
                sx={{
                  background: !isShowTaskPie ? "rgba(55, 102, 143, 1)" : "#fff",
                  color: !isShowTaskPie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowTaskPie(false)}
              >
                <SignalCellularAltIcon
                  style={{ width: "20px", height: "20px" }}
                />
              </Typography>
            </Box>
          </Box>
          {isShowTaskPie ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={6}>
                <Pie data={taskPieData} />
              </Grid>
            </Box>
          ) : (
            <TaskStatusBar taskBarData={taskBarData} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              background: "#E0F2FE",
              padding: 2,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {" "}
              <Typography variant="h6">Milestone Status</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1.5 }}>
              <Typography
                sx={{
                  background: isShowMilestonePie
                    ? "rgba(55, 102, 143, 1)"
                    : "#fff",
                  color: isShowMilestonePie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowMilestonePie(true)}
              >
                <AiOutlinePieChart style={{ width: "20px", height: "20px" }} />
              </Typography>
              <Typography
                sx={{
                  background: !isShowMilestonePie
                    ? "rgba(55, 102, 143, 1)"
                    : "#fff",
                  color: !isShowMilestonePie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowMilestonePie(false)}
              >
                <SignalCellularAltIcon
                  style={{ width: "20px", height: "20px" }}
                />
              </Typography>
            </Box>
          </Box>
          {isShowMilestonePie ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={6}>
                <Pie data={milestonePieData} />
              </Grid>
            </Box>
          ) : (
            <MilestoneStatusBar milestoneBarData={milestoneBarData} />
          )}
        </Box>
      </Grid>

      {/* Row 2: Weekly Report Chart with Week Filter */}
      <Grid item xs={12} md={6}>
        <Box style={{ height: "100%" }}>
          <Box
            sx={{
              background: "#E0F2FE",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              padding: 1,
              display: "flex",
              gap: 2,
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box item xs={8}>
              <Typography variant="h6">Weekly Report</Typography>
            </Box>
            {/* Week Selection Dropdown for Weekly Report */}
            <Box>
              {/* <FormControl sx={{ width: "100px" }}> */}
                {/* <InputLabel>Week</InputLabel> */}
                <select
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(e.target.value)}
                  // fullWidth
                  size="small"
                  displayEmpty
                  style={{
                    backgroundColor: "#FBFBFB",
                    border: "1px solid #F2F4F7",
                    borderRadius: "8px",
                    color: "#98A2B3",
                    padding: "8px",
                    fontSize: "16px",
                  }}
                >
                  <option value="W1">Week 01</option>
                  <option value="W2">Week 02</option>
                  <option value="W3">Week 03</option>
                  <option value="W4">Week 04</option>
                </select>
              {/* </FormControl> */}
            </Box>
          </Box>
          <Bar data={weeklyReportData} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: "100%",
            padding: "20px",
            background: "#E0F2FE",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Overdue Tasks
          </Typography>
          <TableContainer sx={{ maxHeight: "45vh", overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#37668f",
                      color: "#ffff",
                      borderTopLeftRadius: 10,
                    }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#37668f", color: "#ffff" }}
                  >
                    Task
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#37668f", color: "#ffff" }}
                  >
                    Overdue
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#37668f",
                      color: "#ffff",
                      borderTopRightRadius: 10,
                    }}
                  >
                    All Open
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ background: "#ffff" }}>
                {overdueItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Display User Initials in a circle with colored background */}
                      <Avatar
                        sx={{
                          bgcolor: generateRandomColor(),
                          width: 32,
                          height: 32,
                          marginRight: 1,
                        }}
                      >
                        {getUserInitials(item.user)}
                      </Avatar>
                      {item.user}
                    </TableCell>

                    <TableCell>
                      {/* Tooltip to show full task name on hover */}
                      <MUItoolTip title={item.task} arrow>
                        <span
                          style={{
                            display: "inline-block",
                            maxWidth: "150px", // Limit width for truncation
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.task}
                        </span>
                      </MUItoolTip>
                    </TableCell>

                    <TableCell>{item.overdue}</TableCell>
                    <TableCell>{item.allOpen}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>

      {/* Row 3: Tags and Timesheet Report */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: "100%",
            padding: "20px",
            background: "#E0F2FE",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Tags
          </Typography>
          <TableContainer
            sx={{ maxHeight: "45vh", overflowY: "auto", padding: 2 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#37668f",
                      color: "#ffff",
                      borderTopLeftRadius: 10,
                    }}
                  >
                    Tags Name
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#37668f",
                      color: "#ffff",
                      borderTopRightRadius: 10,
                    }}
                  >
                    Used
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ background: "#ffff" }}>
                {Object.keys(tags).map((tag, index) => (
                  <TableRow key={index}>
                    <TableCell>{tag}</TableCell>
                    <TableCell>{`${tags[tag]} times`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      {/* Timesheet Report */}

      <Grid item xs={12} md={6}>
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              background: "#E0F2FE",
              padding: 2,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {" "}
              <Typography variant="h6">Timesheet Reports</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1.5 }}>
              <Typography
                sx={{
                  background: isShowTimesheetPie
                    ? "rgba(55, 102, 143, 1)"
                    : "#fff",
                  color: isShowTimesheetPie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowTimesheetPie(true)}
              >
                <AiOutlinePieChart style={{ width: "20px", height: "20px" }} />
              </Typography>
              <Typography
                sx={{
                  background: !isShowTimesheetPie
                    ? "rgba(55, 102, 143, 1)"
                    : "#fff",
                  color: !isShowTimesheetPie ? "white" : "black",
                  borderRadius: 50,
                  padding: 0.5,
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="commonShadow"
                onClick={() => setIsShowTimesheetPie(false)}
              >
                <SignalCellularAltIcon
                  style={{ width: "20px", height: "20px" }}
                />
              </Typography>
            </Box>
          </Box>
          {isShowTimesheetPie ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={6}>
                <Pie data={timesheetPieData} />
              </Grid>
            </Box>
          ) : (
            <TimesheetBar timesheetBarData={timesheetBarData} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Charts;
