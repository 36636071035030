import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

import "./App.css";
import "./styles/module.css";
import "./styles/theme.css";
import "./index.css";
import "./styles/layout.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
  <BrowserRouter>
  <ToastContainer />
    <Provider store={store}>
    <GoogleOAuthProvider clientId="839402426498-5oumtgeu8i3bs3m9jprhui1u45j30rjm.apps.googleusercontent.com">
      <App />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
</StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
