import React, { useRef, useState } from "react";
import GanttComponent from "./GanttChart/Gantt";
import GanttComponentProject from "./GanttChartProject/Gantt";

import { Box, Button } from "@mui/material";

const Reports = () => {
  const [showOwnerReport, setShowOwnerReport] = useState(true);

  return (
    <>
      <Button onClick={() => setShowOwnerReport(true)}>Owner wise</Button>
      <Button onClick={() => setShowOwnerReport(false)}>All reports</Button>

      {showOwnerReport ? <GanttComponent /> : <GanttComponentProject />}
    </>
  );
};
export default Reports;
