import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  Divider,
  FormControl,
  Select as MUISelect,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import CustomDropdown from "./CustomDropdown";

export default function UpdateTimeDrawer({
  open,
  onClose,
  refresh,
  setRefresh,
  timeData,
}) {
  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid;

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const role = userInfo.userrole;
  const userid = userInfo.id;
  const useremail = userInfo.email;

  // console.log(useremail, "useremail")

  const [data, setData] = useState({
    subtask: "",
    subtaskid: null,
    taskid: null,
    task: "",
    projectid: projectId,
    milestoneid: null,
    issuename: "",
    issueid: null,
    owner: [],
    date: "",
    status: "Pending",
    fromlogtime: null,
    tologtime: null,
    note: "",
    modifiedby: username,
    companyid,
    companyemail,
    userid,
    billable: "",
  });
  const [users, setUsers] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [milestoneData, setMilestoneData] = useState([]);
  const [subtaskData, setSubtaskData] = useState([]);

  //   const [task, setTask] = useState(null);
  const [taskValue, setTaskValue] = useState("");
  const [fromLogTimeValue, setFromLogTimeValue] = useState(
    dayjs("2022-04-17T24:00")
  );
  const [toLogTimeValue, setToLogTimeValue] = useState(
    dayjs("2022-04-17T24:00")
  );
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [issueData, setIssueData] = useState([]);
  const [issueValue, setIssueValue] = useState("");
  const [radioValue, setRadioValue] = useState("task");

  useEffect(() => {
    if (timeData) {
      setData({
        id: timeData.id,
        subtask: timeData.subtask || "",
        subtaskid: timeData.subtaskid || null,
        taskid: timeData.taskid || null,
        task: timeData.task || "",
        projectid: timeData.projectid || projectId,
        milestoneid: timeData.milestoneid || null,
        issuename: timeData.issuename || "",
        issueid: timeData.issueid || null,
        owner:
          timeData.owner?.map((owner) => ({
            value: owner,
            label: owner,
          })) || [],
        date: timeData.date || "",
        status: timeData.status || "Pending",
        fromlogtime: timeData.fromlogtime || null,
        tologtime: timeData.tologtime || null,
        note: timeData.note || "",
        modifiedby: timeData.modifiedby || username,
        companyid,
        companyemail,
        userid,
        billable: timeData.billable || "",
      });
      setToLogTimeValue(dayjs(timeData.tologtime));
      setFromLogTimeValue(dayjs(timeData.fromlogtime));
      handleSubtaskChange(timeData.subtaskid);

      setIssueValue({ id: timeData.issueid, issuename: timeData.issuename });

      if (timeData.subtask) {
        setRadioValue("task");
      } else {
        setRadioValue("issue");
      }
    }
  }, [
    timeData,
    projectName,
    projectId,
    username,
    companyname,
    companyid,
    companyemail,
    useremail,
    userid,
  ]);

  console.log(issueValue, "issue value");

  useEffect(() => {
    // Milestone get
    axios
      .post(`https://pms.vliv.app/milestone/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        setMilestoneData(res.data.length > 0 ? res.data : []);
        // console.log(res.data, "task data");
      })
      .catch((err) => console.log(err));

    // Task get
    axios
      .post(`https://pms.vliv.app/subtask/getall`, {
        companyid,
        userid,
        owner: username,
      })
      .then((res) => {
        console.log(res.data, "get all subtask");
        setSubtaskData(res.data);
      })
      .catch((err) => console.log(err));

    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => {
        setUsers(res.data);

        setOwnerOptions(
          res.data.map((user) => ({
            value: user.userid,
            label: user.username,
          }))
        );
      })
      .catch((err) => console.log(err));

    // To get issue data
    axios
      .post(`https://pms.vliv.app/issue/get`, {
        companyid,
        userid,
        projectid: projectId,
      })
      .then((res) => {
        // console.log(res.data, "issue data");
        setIssueData(res.data);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubtaskChange = (subtaskId) => {
    const selected = subtaskData.find(
      (item) => item.id === parseInt(subtaskId)
    );
    setSelectedSubtask(selected);
    setTaskValue(selected); // This will also update your task value as per your current logic
  };

  const handleOwnerChange = (selectedOptions) => {
    setData((prevData) => ({
      ...prevData,
      owner: selectedOptions,
    }));
  };

  const handleSave = async () => {
    try {
      const ownersArray = data.owner.map((owner) => owner.label);
      const requestData = {
        ...data,
        owner: ownersArray,
        fromlogtime: fromLogTimeValue,
        tologtime: toLogTimeValue,
        taskid: (taskValue && taskValue.taskid) || null,
        task: (taskValue && taskValue.task) || "",
        milestoneid: (taskValue && taskValue.milestoneid) || null,
        subtask: (taskValue && taskValue.subtask) || "",
        subtaskid: (taskValue && taskValue.id) || null,
        issuename: issueValue.issuename,
        issueid: issueValue.id,
      };

      // console.log(requestData, "data in save");
      const res = await axios.post(
        "https://pms.vliv.app/timesheet/update",
        requestData
      );
      if (res.data.message == "Timesheet updated successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error saving project:", error);
      alert("Failed to save project");
    }
  };

  const handleQuillChange = (value, name) => {
    setData({ ...data, [name]: value });
  };

  // console.log(taskValue, "task value data");
  //   console.log(subtaskData, "sub task data");
  //   console.log(taskData, "task data");
  //  console.log(timeData, 'time data');

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "40vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw", // adjust width for smaller screens
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          gap={2}
        >
          {" "}
          <p className="drawer-heading">Update Time Sheet</p>
        </Box>
        <Divider />

        {/* Radio button */}

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Choose</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValue}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            <FormControlLabel value="task" control={<Radio />} label="Task" />
            <FormControlLabel value="issue" control={<Radio />} label="Issue" />
          </RadioGroup>
        </FormControl>

        <Box className="drawer-form-container">
          <Grid container spacing={1.3}>
            {radioValue == "task" ? (
              <Grid item xs={12}>
                <label>Subtask</label>
                <CustomDropdown
                  subtaskData={subtaskData}
                  selectedSubtask={selectedSubtask}
                  onSubtaskChange={handleSubtaskChange}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <label>Issue</label>
                <MUISelect
                  fullWidth
                  onChange={(e) => setIssueValue(e.target.value)}
                  value={issueValue}
                  renderValue={(selected) =>
                    selected?.issuename || "Select Issue"
                  }
                >
                  <MenuItem>select</MenuItem>
                  {issueData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.issuename}
                    </MenuItem>
                  ))}
                </MUISelect>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>Timesheet Information</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="form-label">Date</p>
              <TextField
                type="date"
                name="date"
                value={data.date}
                onChange={handleInputChange}
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="form-label">Owner</p>
              <Select
                isMulti
                options={ownerOptions}
                value={data.owner}
                onChange={handleOwnerChange}
                placeholder="Select Owner(s)"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className="form-label">Daily log</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    value={fromLogTimeValue}
                    onChange={(newValue) => setFromLogTimeValue(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>To</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    value={toLogTimeValue}
                    onChange={(newValue) => setToLogTimeValue(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <p className="form-label">Billable</p>
              <MUISelect
                name="billable"
                value={data.billable}
                onChange={handleInputChange}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </MUISelect>
            </Grid>

            <Grid item xs={12}>
              <p className="form-label">Note</p>
              <ReactQuill
                theme="snow"
                value={data.note}
                onChange={(value) => handleQuillChange(value, "note")}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }], // Add alignment options here
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "list",
                  "bullet",
                  "bold",
                  "italic",
                  "underline",
                  "link",
                  "color",
                  "background",
                  "align", // Add 'align' to formats
                ]}
                style={{
                  height: "150px",
                  marginBottom: "20px",
                  borderRadius: "5px",
                  fontSize: "large",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-start"
            gap={1}
            flexWrap="wrap"
            my={5}
          >
            <Button variant="outlined" className="cancelBtn" onClick={onClose}>
              Cancel
            </Button>
            <Button className="saveBtn" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
