import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";

export default function UpdateTaskDrawer({
  open,
  onClose,
  taskData,
  refresh,
  setRefresh,
}) {
  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid;

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const userid = userInfo.id;
  const useremail = userInfo.email;

  const [data, setData] = useState({
    id: "",
    task: "",
    projectname: projectName,
    projectid: projectId,
    milestone: "",
    milestoneid: null,
    owner: [],
    startdate: "",
    enddate: "",
    priority: "",
    tag: [],
    description: "",
    reminder: "",
    workhours: "",
    createdby: username,
    modifiedby: username,
    companyname,
    companyid: companyid,
    companyemail: companyemail,
    username: username,
    useremail,
    userid: userid,
  });
  const [milestoneData, setMilestoneData] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [milestoneValue, setMilestoneValue] = useState(null);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/milestone/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        setMilestoneData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));

    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => {
        setOwnerOptions(
          res.data.map((user) => ({
            value: user.userid,
            label: user.username,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [refresh, taskData, companyid, projectName]);

  useEffect(() => {
    if (taskData) {
      setData({
        id: taskData.id || "",
        task: taskData.task || "",
        projectname: taskData.projectname || projectName,
        projectid: taskData.projectid || projectId,
        milestone: taskData.milestone || "",
        milestoneid: taskData.milestoneid || null,
        owner:
          taskData.owner?.map((owner) => ({
            value: owner,
            label: owner,
          })) || [],
        startdate: taskData.startdate || "",
        enddate: taskData.enddate || "",
        priority: taskData.priority || "",
        tag: taskData.tag || [],
        description: taskData.description || "",
        reminder: taskData.reminder || "",
        workhours: taskData.workhours || "",
        createdby: username,
        modifiedby: username,
        companyname,
        companyid: companyid,
        companyemail: companyemail,
        username: username,
        useremail,
        userid: userid,
      });

      if (milestoneData.length > 0 && taskData.milestoneid) {
        const selectedMilestone = milestoneData.find(
          (m) => m.id === taskData.milestoneid
        );
        setMilestoneValue(selectedMilestone || null);
      }
    }
  }, [
    taskData,
    milestoneData,
    projectName,
    projectId,
    username,
    companyname,
    companyid,
    companyemail,
    useremail,
    userid,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMilestoneInputChange = (e) => {
    const selectedMilestone = milestoneData.find(
      (m) => m.id === e.target.value.id
    );
    setMilestoneValue(selectedMilestone || null);
  };

  const handleOwnerChange = (selectedOptions) => {
    setData((prevData) => ({
      ...prevData,
      owner: selectedOptions,
    }));
  };

  const handleTagChange = (event, newValue) => {
    setData((prevData) => ({
      ...prevData,
      tag: newValue,
    }));
  };

  const handleSave = async () => {
    try {
      const ownersArray = data.owner.map((owner) => owner.label);
      const requestData = {
        ...data,
        owner: ownersArray,
        milestone: milestoneValue ? milestoneValue.milestone : "",
        milestoneid: milestoneValue ? milestoneValue.id : null,
      };

      const res = await axios.post(
        "https://pms.vliv.app/task/update",
        requestData
      );
      
      if (res.data.message == "Task updated successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error saving task:", error);
      alert("Failed to save task");
    }
  };

  console.log(data.milestone, "data milestone");

  return (
    <Box sx={{ width: 500, padding: 3 }}>
      <Typography variant="h6">Update Task</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label>Task Name</label>
          <TextField
            name="task"
            value={data.task}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <label>Project Name</label>
          <TextField
            name="projectname"
            value={data.projectname}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <label>Milestone</label>
          <FormControl fullWidth>
            <MUISelect
              value={milestoneValue}
              onChange={handleMilestoneInputChange}
            >
              <MenuItem value={null}>Select</MenuItem>
              {milestoneData.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.milestone}
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <p className="form-label">Owner</p>
          <ReactSelect
            isMulti
            options={ownerOptions}
            value={data.owner}
            onChange={handleOwnerChange}
            placeholder="Select Owner(s)"
          />
        </Grid>
        <Grid item xs={6}>
          <p>Start Date</p>
          <TextField
            type="date"
            name="startdate"
            value={data.startdate}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <p>End Date</p>
          <TextField
            type="date"
            name="enddate"
            value={data.enddate}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <label>Priority</label>
          <FormControl fullWidth>
            <MUISelect
              name="priority"
              value={data.priority}
              onChange={handleInputChange}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </MUISelect>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <label>Tags</label>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={data.tag}
            onChange={handleTagChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Add tags" />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <label>Description</label>
          <TextField
            name="description"
            value={data.description}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Work Hours</label>
          <TextField
            type="time"
            name="workhours"
            value={data.workhours}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 2, // allows selecting time in HH:MM:SS format
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Reminder</label>
          <FormControl fullWidth>
            <InputLabel id="reminder-label">Reminder</InputLabel>
            <MUISelect
              labelId="reminder-label"
              name="reminder"
              value={data.reminder}
              onChange={handleInputChange}
              label="Reminder"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </MUISelect>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button variant="contained" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#37668F" }}
          onClick={handleSave}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
}
