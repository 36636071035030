// File: /path_to_component/Repository.js

import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Select, MenuItem, Typography } from "@mui/material";
import ActiveProjects from "../ActiveProjects/ActiveProjects";
import PublicProjects from "../PublicProjects/PublicProjects";
import axios from "axios";
import Cookies from "js-cookie";
import Dashboard from "./Dashboard/Dashboard";
import Tasks from "./Tasks/Tasks";
import Reports from "./Reports/Reports";
import Documents from "./Documents/Documents";
import Milestone from "./Milestone/Milestone";
import Issues from "./Issues/Issues";
import Users from "./Users/Users";
import { useNavigate, useParams } from "react-router-dom";
import TimeSheets from "./TimeSheets/TimeSheets";
import Subtask from "./Tasks/Subtask/Subtask";
import { MyContextProvider } from "../../ContextApi/MyContext";
import Billings from "./Billings/Billings";

function DetailedProjectTab() {
  const navigate = useNavigate();
  const url = useParams();
  const projectName = url.projectname;

  // console.log(projectName, "url");

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const email = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.id;
  const role = userInfo.userrole;

  const [selectedTab, setSelectedTab] = useState(0);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(projectName);
  const [refresh, setRefresh] = useState(false);

  const [isOpenSubtask, setIsOpenSubTask] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleProjectChange = (event) => {
    const projectname = event.target.value;
    setSelectedProject(projectname);
    setRefresh(!refresh);
    navigate(`/pms/projects/${projectname}`);
  };

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/projects/get`, {
        companyid,
        role,
        userid,
      })
      .then((res) => {
        setProjects(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));

    // alert('get')
  }, [refresh]);

  return (
    <MyContextProvider>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          className="common-background-color"
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            sx={{ display: "flex", alignItems: "center", px: 2 }}
          >
            <Tab
              label="Dashboard"
              className={
                selectedTab == 0
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Tasks"
              className={
                selectedTab == 1
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Reports"
              className={
                selectedTab == 2
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Documents"
              className={
                selectedTab == 3
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Milestone"
              className={
                selectedTab == 4
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Timesheets"
              className={
                selectedTab == 5
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Issues"
              className={
                selectedTab == 6
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Users"
              className={
                selectedTab == 7
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />
            <Tab
              label="Billing"
              className={
                selectedTab == 8
                  ? "common-tabs third-nav-btn-active"
                  : "common-tabs third-nav-btn"
              }
            />{" "}
            {/* Last Tab without margin */}
          </Tabs>

          <select
            value={selectedProject}
            onChange={handleProjectChange}
            displayEmpty
            style={{
              backgroundColor: "#FBFBFB",
              border: "1px solid #F2F4F7",
              borderRadius: "8px",
              color: "#98A2B3",
              padding: "8px",
              fontSize: "16px",
            }}
          >
            <option value="" disabled>
              Select a Project
            </option>
            {projects.map((item, index) => (
              <option key={index} value={item.projectname}>
                {item.projectname}
              </option>
            ))}
          </select>
        </Box>

        <Box sx={{ padding: 2, mt: 0.5 }}>
          {selectedTab === 0 && <Dashboard />}
          {selectedTab === 1 &&
            (isOpenSubtask ? (
              <Subtask
                refresh={refresh}
                setRefresh={setRefresh}
                setIsOpenSubTask={setIsOpenSubTask}
              />
            ) : (
              <Tasks
                refresh={refresh}
                setRefresh={setRefresh}
                setIsOpenSubTask={setIsOpenSubTask}
              />
            ))}
          {selectedTab === 2 && (
            <Reports refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 3 && (
            <Documents refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 4 && (
            <Milestone refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 5 && (
            <TimeSheets refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 6 && (
            <Issues refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 7 && (
            <Users refresh={refresh} setRefresh={setRefresh} />
          )}
          {selectedTab === 8 && (
            <Billings refresh={refresh} setRefresh={setRefresh} />
          )}
        </Box>
      </Box>
    </MyContextProvider>
  );
}

export default DetailedProjectTab;
