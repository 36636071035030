import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  Divider,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { getChipColor } from "../../../../Constrant/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

export default function UpdateProjectDrawer({
  open,
  onClose,
  projectData,
  refresh,
  setRefresh,
}) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const role = userInfo.userrole;
  const userid = userInfo.id;
  const useremail = userInfo.email;

  console.log(projectData, "project data");

  const [data, setData] = useState({
    id: "",
    projectname: "",
    owner: "",
    status: "",
    task: "",
    milestone: "",
    startdate: "",
    enddate: "",
    completiontime: "",
    projectgroup: "",
    tag: [],
    description: "",
    createdby: "",
    modifiedby: username,
    companyname,
    companyid,
    companyemail,
    userid,
    username,
    useremail,
    cost: null,
    client: "",
  });

  const [users, setUsers] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);

  useEffect(() => {
    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => {
        setUsers(res.data);
        setOwnerOptions(
          res.data.map((user) => ({
            value: user.userid,
            label: user.username,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [companyid]);

  useEffect(() => {
    if (projectData) {
      setData({
        id: projectData.id,
        projectname: projectData.projectname || "",
        owner:
          (projectData.owner &&
            projectData.owner.map((owner) => ({
              value: owner,
              label: owner,
            }))) ||
          [],
        status: projectData.status || "",
        task: projectData.task || "",
        milestone: projectData.milestone || "",
        startdate: projectData.startdate || "",
        enddate: projectData.enddate || "",
        completiontime: projectData.completiontime || "",
        projectgroup: projectData.projectgroup || "",
        tag: projectData.tag || [],
        description: projectData.description || "",
        createdby: projectData.username || "",
        modifiedby: username,
        companyname,
        companyid,
        companyemail,
        username,
        useremail,
        userid,
        cost: projectData.cost || null,
        client: projectData.client || "",
      });
    }
  }, [projectData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOwnerChange = (selectedOptions) => {
    setData((prevData) => ({
      ...prevData,
      owner: selectedOptions,
    }));
  };

  const handleSave = async () => {
    try {
      const ownersArray = data.owner.map((owner) => owner.label); // Convert selected owners to an array of usernames
      const requestData = { ...data, owner: ownersArray }; // Update the owner field before sending the request

      const res = await axios.post(
        "https://pms.vliv.app/projects/update",
        requestData
      );

      if (res.data.message == "Project Updated successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error saving project:", error);
      alert("Failed to save project");
    }
  };

  const handleTagChange = (event, newValue) => {
    setData((prevData) => ({
      ...prevData,
      tag: newValue,
    }));
  };

  const handleQuillChange = (value, name) => {
    // setData({ ...data, [name]: value });

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        gap={2}
      >
        <p className="drawer-heading">Update Project</p>
      </Box>
      <Divider />
      <Box className="drawer-form-container">
        <Grid container spacing={1.3}>
          <Grid item xs={12}>
            <p className="form-label">Project Name</p>
            <TextField
              name="projectname"
              value={data.projectname}
              onChange={handleInputChange}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <p className="form-label">Client Name</p>
            <TextField
              name="client"
              value={data.client}
              onChange={handleInputChange}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <p className="form-label">Cost</p>
            <TextField
              type="number"
              name="cost"
              value={data.cost}
              onChange={handleInputChange}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <p className="form-label">Owner</p>
            <Select
              isMulti
              options={ownerOptions}
              value={data.owner}
              onChange={handleOwnerChange}
              placeholder="Select Owner(s)"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <p className="form-label">Project Group</label>
            <TextField
              name="projectgroup"
              value={data.projectgroup}
              onChange={handleInputChange}
              fullWidth
              size="small"
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <p className="form-label">Start Date</p>
            <TextField
              type="date"
              name="startdate"
              value={data.startdate}
              onChange={handleInputChange}
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <p className="form-label">End Date</p>
            <TextField
              type="date"
              name="enddate"
              value={data.enddate}
              onChange={handleInputChange}
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <p className="form-label">Tags</p>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={data.tag}
              onChange={handleTagChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: getChipColor(option),
                      color: "#fcfcfc",
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="" size="small" />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <p className="form-label">Description</p>
            <div style={{ width: "100%", margin: 2 }}>
              <ReactQuill
                theme="snow"
                value={data.description}
                onChange={(value) => handleQuillChange(value, "description")}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }], // Add alignment options here
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "list",
                  "bullet",
                  "bold",
                  "italic",
                  "underline",
                  "link",
                  "color",
                  "background",
                  "align", // Add 'align' to formats
                ]}
                style={{
                  height: "150px",
                  marginBottom: "20px",
                  borderRadius: "5px",
                  fontSize: "large",
                  width: "100%",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-start"
          gap={1}
          flexWrap="wrap"
          my={5}
        >
          <Button variant="outlined" className="cancelBtn" onClick={onClose}>
            Cancel
          </Button>
          <Button className="saveBtn" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Box>
    </>
  );
}
