export function transformApiDataToGanttTasks(apiData) {
  const tasks = [];
  const currentDate = new Date();

  apiData.forEach((item) => {
    // Ensure valid dates
    const start = item.startdate ? new Date(item.startdate) : currentDate;
    const end = item.enddate ? new Date(item.enddate) : currentDate;

    // If the task has multiple owners, create separate tasks for each owner
    item.owner.forEach((owner) => {
      tasks.push({
        id: item.id.toString(), // Task ID
        name: item.task || "Untitled Task", // Task name
        start: isNaN(start.getTime()) ? currentDate : start, // Fallback to current date
        end: isNaN(end.getTime()) ? currentDate : end, // Fallback to current date
        type: "task", // Mark as a task
        project: item.projectid ? item.projectid.toString() : null, // Group by project ID
        createdby: owner, // Owner of the task
        hideChildren: false, // Default to show tasks
        progress: item.status === "Closed" ? 100 : 0, // Sample logic for progress
      });
    });
  });

  // Group tasks by project and create a project item if it doesn't exist
  const projects = Array.from(new Set(apiData.map(item => item.projectid)))
    .map((projectId) => {
      const projectName = apiData.find(item => item.projectid === projectId)?.projectname || "Untitled Project";

      // Calculate project start and end dates based on the tasks under this project
      const projectTasks = tasks.filter((task) => task.project === projectId.toString());
      const [projectStart, projectEnd] = getStartEndDateForProject(projectTasks);

      return {
        id: projectId.toString(),
        name: projectName,
        start: projectStart,
        end: projectEnd,
        type: "project", // Project type
        hideChildren: false, // Project starts with children hidden
        progress: 0, // No progress tracking for the project itself
      };
    });

  return [...projects, ...tasks];
}

// Helper function to get start and end dates for a project based on its tasks
export function getStartEndDateForProject(tasks) {
  let start = tasks[0]?.start || new Date();
  let end = tasks[0]?.end || new Date();

  tasks.forEach((task) => {
    if (task.start.getTime() < start.getTime()) {
      start = task.start;
    }
    if (task.end.getTime() > end.getTime()) {
      end = task.end;
    }
  });

  return [start, end];
}
