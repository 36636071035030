import React, { useState } from "react";
import {
  Box,
  Button,
  Popover,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function CustomDropdown({ subtaskData, selectedSubtask, onSubtaskChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSubtasks, setFilteredSubtasks] = useState(subtaskData);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term) {
      const filtered = subtaskData.filter((item) =>
        item.subtask.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredSubtasks(filtered);
    } else {
      setFilteredSubtasks(subtaskData);
    }
  };

  const handleSubtaskSelect = (event) => {
    onSubtaskChange(event.target.value);
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{ textAlign: "left", width: "100%" }}
      >
        {selectedSubtask ? selectedSubtask.subtask : "Select a Subtask"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} width={300}>
          <TextField
            placeholder="Search subtasks"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {filteredSubtasks.length > 0 ? (
            filteredSubtasks
              .reduce((acc, item) => {
                const taskIndex = acc.findIndex(
                  (group) => group.task === item.task
                );
                if (taskIndex !== -1) {
                  acc[taskIndex].subtasks.push(item);
                } else {
                  acc.push({ task: item.task, subtasks: [item] });
                }
                return acc;
              }, [])
              .map((group, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="subtitle1" gutterBottom>
                    {group.task}
                  </Typography>
                  <RadioGroup
                    name="subtask"
                    value={selectedSubtask ? selectedSubtask.id : ""}
                    onChange={handleSubtaskSelect}
                  >
                    {group.subtasks.map((subtask) => (
                      <FormControlLabel
                        key={subtask.id}
                        value={subtask.id}
                        control={<Radio />}
                        label={subtask.subtask}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              ))
          ) : (
            <Typography variant="body2" mt={2}>
              No subtasks found.
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
}
