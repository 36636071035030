import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import Select from "react-select";

export default function AddMileDrawer({ open, onClose, refresh, setRefresh }) {
  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid
  // console.log(projectId, "project id")

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const userid = userInfo.id;
  const useremail = userInfo.email

  const [data, setData] = useState({
    projectname: projectName,
    projectid: projectId,
    owner: [],
    status: "Open",
    milestone: "",
    startdate: "",
    enddate: "",
    completiontime: "",
    tag: [],
    description: "",
    createdby: username,
    modifiedby: username,
    companyname,
    companyid,
    companyemail,
    username,
    useremail,
    userid,
  });
  const [ownerOptions, setOwnerOptions] = useState([]);

  useEffect(() => {
    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => {
        setOwnerOptions(
          res.data.map((user) => ({
            value: user.userid,
            label: user.username,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOwnerChange = (selectedOptions) => {
    setData((prevData) => ({
      ...prevData,
      owner: selectedOptions,
    }));
  };

  const handleTagChange = (event, newValue) => {
    setData((prevData) => ({
      ...prevData,
      tag: newValue,
    }));
  };

  const handleSave = async () => {
    try {
      const ownersArray = data.owner.map((owner) => owner.label); // Convert selected owners to an array of usernames
      const requestData = { ...data, owner: ownersArray }; // Update the owner field before sending the request

      const res = await axios.post("https://pms.vliv.app/milestone/add", requestData);
         
      if (res.data.message == "Milestone added successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error saving milestone:", error);
      alert("Failed to save milestone");
    }
  };

  return (
 
      <Box sx={{ width: 500, padding: 3 }}>
        <Typography variant="h6">Add New Milestone</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label>Milestone Name</label>
            <TextField
              name="milestone"
              value={data.milestone}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <label>Project Name</label>
            <TextField
              name="projectname"
              value={data.projectname}
              onChange={handleInputChange}
              fullWidth
              disabled
            />
          </Grid>
          {/* <Grid item xs={6}>
            <label>Milestone</label>
            <TextField
              name="milestone"
              value={data.milestone}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <p className="form-label">Owner</p>
            <Select
              isMulti
              options={ownerOptions}
              value={data.owner}
              onChange={handleOwnerChange}
              placeholder="Select Owner(s)"
            />
          </Grid>

          <Grid item xs={6}>
            <label>Start Date</label>
            <TextField
              type="date"
              name="startdate"
              value={data.startdate}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <label>End Date</label>
            <TextField
              type="date"
              name="enddate"
              value={data.enddate}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <label>Tags</label>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={data.tag}
              onChange={handleTagChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Add tags" />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <label>Description</label>
            <TextField
              name="description"
              value={data.description}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" sx={{ mr: 2 }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#37668F" }}
            onClick={handleSave}
          >
            Create
          </Button>
        </Box>
      </Box>
   
  );
}
