import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import dayjs from "dayjs";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Drawer,
  Container,
  Popover,
  Box,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Chip,
  Avatar,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Cookies from "js-cookie";

import { MdDeleteSweep } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import AddProjectDrawer from "./AddProjectDrawer";
import Select from "react-select";
import UpdateProjectDrawer from "./UpdateProjectDrawer";

import { styled } from "@mui/material/styles";
import {
  generateAvatarColor,
  getChipColor,
  getInitials,
  getStatusBackgroundColor,
} from "../../../../Constrant/constants";

const CustomCheckbox = styled("span")({
  color: "black",
});

export default function ActiveProjects() {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const role = userInfo.userrole;
  const userid = userInfo.id;

  // console.log(userid, "user id");

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerEditOpen, setDrawerEditOpen] = useState(false);

  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch Projects Data
    axios
      .post(`https://pms.vliv.app/projects/get`, { companyid, role, userid })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/projects/delete`, {
          ids: selectionModel,
        })
        .then((res) => {
          alert(res.data.message);
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerEditOpen(true);
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setDrawerEditOpen(false);
  };

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://pms.vliv.app/projects/status`, { id, status: newStatus })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateProject = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const openPopover = Boolean(anchorEl);

  // For the assign users start
  const handleOpenAssignPopover = (event, projectId, projectName) => {
    setAnchorElAssign(event.currentTarget);
    setSelectedProjectId(projectId);
    setSelectedProjectName(projectName);

    //  console.log(projectName, "project name");

    axios
      .post(`https://pms.vliv.app/pmsuser/getassigned`, {
        projectid: projectId,
      })
      .then((res) => {
        setAssignedUsers(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleCloseAssignPopover = () => {
    setAnchorElAssign(null);
    setSelectedProjectId(null);
    setSelectedProjectName(null);
    setAssignedUsers([]);
    setSelectedUser([]);
  };

  const handleAddUser = (user) => {
    if (!selectedUser.includes(user)) {
      setSelectedUser([...selectedUser, user]);
    }
  };

  console.log(selectedUser, "selected users");

  const handleRemoveUser = (userId) => {
    axios
      .post(`https://pms.vliv.app/pmsuser/remove`, {
        projectid: selectedProjectId,
        userid: userId,
      })
      .then((res) => {
        // alert(res.data.message);
        setRefresh(!refresh);
        setAssignedUsers(
          assignedUsers.filter((user) => user.userid !== userId)
        );
      })
      .catch((err) => console.log(err));
  };

  // This is for assign the user
  const handleAssignSave = (user) => {
    axios
      .post(`https://pms.vliv.app/pmsuser/add`, {
        projectid: selectedProjectId,
        projectname: selectedProjectName,
        selectedUser: user,
      })
      .then((res) => {
        // This APi call is basically disable the selected users at the same time by calling the assigned API
        axios
          .post(`https://pms.vliv.app/pmsuser/getassigned`, {
            projectid: selectedProjectId,
          })
          .then((res) => {
            setAssignedUsers(res.data);
          })
          .catch((err) => console.log(err));

        setRefresh(!refresh);
        handleClosePopover();
      })
      .catch((err) => console.log(err));
  };

  const openAssignPopover = Boolean(anchorElAssign);
  // For the assign users end

  const columns = [
    {
      field: "projectname",
      headerName: "Project Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "client",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "percent",
      headerName: "Completion %",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const getColor = (percent) => {
          if (!percent) return "error";
          if (percent > 0 && percent <= 50) return "warning";
          if (percent > 50 && percent < 100) return "primary";
          if (percent == 100) return "success";
        };

        return (
          <Chip
            label={`${params.row.percent || 0} %`}
            size="small"
            color={getColor(params.row.percent)}
          />
        );
      },
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        const owners = params.row.owner || [];
        const firstOwner = owners[0] || "-"; // Fallback if no owners exist
        const additionalOwnersCount = owners.length - 1;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Placeholder avatar with the first letter of the owner's name */}
            <Avatar
              sx={{
                bgcolor: generateAvatarColor(firstOwner),
                fontSize: "10px",
                height: "28px",
                width: "28px",
                marginRight: "3px",
              }}
            >
              {getInitials(firstOwner)}
            </Avatar>
            {/* Owner's name */}
            <Box>{firstOwner}</Box>
            {/* If there are more owners, display "+X" inside a circular box */}
            {additionalOwnersCount > 0 && (
              <Box
                height={"28px"}
                width={"28px"}
                fontSize={"14px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  marginLeft: "6px",
                  color: "rgba(55, 102, 143, 1)",
                  border: "1px dashed rgba(55, 102, 143, 1)",
                  padding: "4px",
                  borderRadius: "50%",
                }}
              >
                +{additionalOwnersCount}
              </Box>
            )}
          </div>
        );
      },
    },

    // {
    //   field: "cost",
    //   headerName: "Cost",
    //   flex: 1,
    //   sortable: true,
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <select
          value={params.row.status || "Open"}
          onChange={(e) => {
            e.stopPropagation();
            handleStatusChange(params.row.id, e.target.value);
          }}
          style={{
            width: "100%",
            padding: "6px",
            borderRadius: "20px",
            border: "none",
            backgroundColor: getStatusBackgroundColor(params.row.status),
            color: "#fff",
            appearance: "none", // This hides the default arrow in most browsers
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Open"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Open"
          >
            Open
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Progress"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Progress"
          >
            In Progress
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("To be Tested"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="To be Tested"
          >
            To be Tested
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("On Hold"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="On Hold"
          >
            On Hold
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Review"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Review"
          >
            In Review
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Closed"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Closed"
          >
            Closed
          </option>
        </select>
      ),
    },

    {
      field: "assigned",
      headerName: "Assigned",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenAssignPopover(e, params.row.id, params.row.projectname);
          }}
        >
          Assign
        </Button>
      ),
    },
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "task",
      headerName: "Tasks",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <progress
          value={70}
          max={100}
          style={{ width: "70%", height: "18px" }}
        />
      ),
    },
    {
      field: "milestone",
      headerName: "Milestone",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <progress
          value={20}
          max={100}
          style={{ width: "70%", height: "18px" }}
        />
      ),
    },
    {
      field: "overdue",
      headerName: "Overdue",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <Box
          sx={{
            color: params.row.overdue ? "red" : "green",
          }}
        >
          {dayjs().format("DD-MM-YYYY")}
        </Box>
      ),
    },
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  // console.log(rows, "rows");
  return (
    <>
      {/* <CssBaseline /> */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Active Projects
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {selectionModel.length > 0 && (
            <MdDeleteSweep
              style={{ width: "25px", height: "25px", color: "red" }}
              onClick={handleDelete}
            />
          )}
        </div>

        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            onRowClick={handleRowClick}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, 100]}
            componentsProps={{
              baseCheckbox: {
                className: "custom-checkbox",
              },
            }}
          />
        </div>

        <Button
          sx={{
            background: "black",
            "&:hover": {
              background: "black",
            },
            color: "white",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
          onClick={handleOpenPopover}
        >
          <FaPlus style={{ width: "30px", height: "30px" }} />
        </Button>

        {/* will be working for styling the button + icon */}

        {/* <Box display={"flex"} justifyContent={"flex-end"} my={2}>
          <Accordion sx={{width:"10%"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{color:"black"}}/>}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{marginLeft:"0px"}}
            >
              <Typography>Header</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box> */}

        <Popover
          open={openAssignPopover}
          anchorEl={anchorElAssign}
          onClose={handleCloseAssignPopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box sx={{ p: 2, width: 300 }}>
            <Typography variant="subtitle1" gutterBottom>
              Assigned Users
            </Typography>
            {/* <ul sx={{}}>
              {assignedUsers.map((user) => (
                <li key={user.userid}>
                  <span style={{ fontSize: "12px" }}>{user.username}</span>
                  <span>
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveUser(user.userid)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </li>
              ))}
            </ul> */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {assignedUsers.map((user) => (
                <Chip
                  key={user.userid}
                  label={user.username}
                  onDelete={() => handleRemoveUser(user.userid)}
                  deleteIcon={<CloseIcon />}
                  sx={{
                    backgroundColor: getChipColor(user.username),
                    color: "#fcfcfc",
                    fontSize: "14px",
                    padding: "6px",
                  }}
                />
              ))}
            </div>

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1" gutterBottom>
              Available Users
            </Typography>

            <List>
              {users.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleAssignSave(user)}
                  disabled={assignedUsers.some(
                    (assigned) => assigned.userid === user.id
                  )}
                >
                  <ListItemText primary={user.username} />
                </ListItem>
              ))}
            </List>
            {/* 
          <Button
            variant="contained"
            fullWidth
            onClick={handleSave}
            sx={{ mt: 2 }}
          >
            Save
          </Button> */}
          </Box>
        </Popover>

        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{ marginBottom: "10px" }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              onClick={handleClosePopover}
            >
              <CloseIcon />
            </IconButton>
            <Button variant="contained" fullWidth onClick={handleCreateProject}>
              Create Project
            </Button>
            <Button variant="contained" fullWidth>
              Create Task
            </Button>
            <Button variant="contained" fullWidth>
              Create Milestone
            </Button>
          </Box>
        </Popover>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleClose}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "40vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw", // adjust width for smaller screens
            },
          },
        }}
      >
        <AddProjectDrawer
          open={drawerOpen}
          onClose={handleClose}
          projectData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={drawerEditOpen}
        onClose={handleClose}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "40vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw", // adjust width for smaller screens
            },
          },
        }}
      >
        <UpdateProjectDrawer
          open={drawerEditOpen}
          onClose={handleClose}
          projectData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>
    </>
  );
}
