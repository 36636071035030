// /src/Charts.js
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Button,
} from "@mui/material";

import { Tooltip as MUItoolTip, Avatar } from "@mui/material";

import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
function TaskStatusBar({ taskBarData }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Bar data={taskBarData} />
    </Box>
  );
}

export default TaskStatusBar;
