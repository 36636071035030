import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
  Popover,
  IconButton,
  Drawer,
} from "@mui/material";
import { MdDeleteSweep } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { addDays, format, startOfDay, endOfDay, add } from "date-fns";
import AddTimeDrawer from "./AddTimeDrawer";
import UpdateTimeDrawer from "./UpdateTimeDrawer";
import "react-date-range/dist/styles.css"; // Main CSS file for react-date-range
import "react-date-range/dist/theme/default.css"; // Default theme for react-date-range
import Cookies from "js-cookie";

export default function TimeSheets({ refresh, setRefresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userid = userInfo.id;
  const userrole = userInfo.userrole;

  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid;

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerUpdateOpen, setDrawerUpdateOpen] = useState(false);
  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/timesheet/getall`, {
        companyid,
        projectid: projectId,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);

        console.log(res.data, "get all timesheet");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  useEffect(() => {
    filterDataByDate();
  }, [dateRange, data]);

  const filterDataByDate = () => {
    const startDate = startOfDay(dateRange[0].startDate);
    const endDate = endOfDay(dateRange[0].endDate);
    const filtered = data.filter((item) => {
      const logDate = new Date(item.date);
      return logDate >= startDate && logDate <= endDate;
    });
    setFilteredData(filtered);
  };

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/timesheet/delete`, {
          ids: selectionModel,
          userid,
        })
        .then(() => {
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerUpdateOpen(true);
    }
  };

  const handleNextDay = () => {
    setDateRange([
      {
        startDate: addDays(dateRange[0].startDate, 1),
        endDate: addDays(dateRange[0].endDate, 1),
        key: "selection",
      },
    ]);
  };

  const handlePreviousDay = () => {
    setDateRange([
      {
        startDate: addDays(dateRange[0].startDate, -1),
        endDate: addDays(dateRange[0].endDate, -1),
        key: "selection",
      },
    ]);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreate = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const handleClose = () => {
    setDrawerUpdateOpen(false);
    setDrawerOpen(false);
    setRowDataForEdit(null);
  };

  const openPopover = Boolean(anchorEl);

  // Functions to calculate and convert the time
  function calculateTimeDifferenceInHours(startTime, endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const differenceInMilliseconds = endDate - startDate;

    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / (1000 * 60)
    );
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )} hrs`;
  }

  function convertTo12HourFormat(isoString) {
    const date = new Date(isoString);
    let hours = date.getHours(); // Get local hours
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  }

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://pms.vliv.app/timesheet/status`, { id, status: newStatus })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      field: "subtask",
      headerName: "Log Title",
      flex: 1,
      renderCell:(params)=>{
        return(
          <>
          {params.row.subtask || params.row.issuename}
          </>
        )
      },  
      sortable: true,
    },
    {
      field: "log",
      headerName: "Daily log Hours",
      flex: 1,
      renderCell: (params) => (
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            mt: 1.5,
          }}
        >
          <Typography>
            {calculateTimeDifferenceInHours(
              params.row.fromlogtime,
              params.row.tologtime
            )}
          </Typography>
        </Box>
      ),
    },
    {
      field: "time",
      headerName: "Time Period",
      flex: 1,
      renderCell: (params) => (
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            mt: 1.5,
          }}
        >
          <Typography>
            {convertTo12HourFormat(params.row.fromlogtime)}
          </Typography>
          <Typography>-</Typography>
          <Typography>{convertTo12HourFormat(params.row.tologtime)}</Typography>
        </Box>
      ),
    },
    {
      field: "owner",
      headerName: "User",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Approval Status",
      flex: 1,
      renderCell: (params) =>
        userrole == "User" ? (
          <>
            {" "}
            <Typography>{params.row.status}</Typography>
          </>
        ) : (
          <>
            <select
              value={params.row.status || "Pending"}
              onChange={(e) => {
                e.stopPropagation();
                handleStatusChange(params.row.id, e.target.value);
              }}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                backgroundColor:
                  params.row.status === "Rejected"
                    ? "red"
                    : params.row.status === "Approved"
                    ? "green"
                    : "orange",
              }}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
          </>
        ),
    },
    {
      field: "billable",
      headerName: "Billable",
      flex: 1,
      sortable: true,
    },
    {
      field: "note",
      headerName: "Notes",
      flex: 1,
      renderCell: (params) => (
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{ width: "100%", fontSize: "1rem" }}
            dangerouslySetInnerHTML={{ __html: params.row.note }}
          />
        </Box>
      ),
    },
  ];

  // console.log(data, 'data')
  // console.log(filteredData, "filtered data")
  return (
    <>
      <CssBaseline />
      <Typography variant="h6" gutterBottom>
        Time Sheets
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <IconButton onClick={handlePreviousDay}>{"<"}</IconButton>
        <Button
          onClick={handleOpenPopover}
          sx={{ color: "black", background: "#f4fefe", fontWeight: "bold" }}
        >
          {`${format(dateRange[0].startDate, "dd/MM/yyyy")} - ${format(
            dateRange[0].endDate,
            "dd/MM/yyyy"
          )}`}
        </Button>
        <IconButton onClick={handleNextDay}>{">"}</IconButton>
      </Box>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ marginBottom: "10px" }}
      >
        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <DateRangePicker
            onChange={(item) => setDateRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            direction="horizontal"
          />
          <IconButton
            onClick={handleClosePopover}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              backgroundColor: "transparent",
              color: "gray",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Popover>

      <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
        <Button
          sx={{
            mb: 1,
            borderRadius: "25px",
            border: "0.1px solid #98A2B3",
            p: 1,
            fontSize: "1.2rem",
            textTransform: "none",
            color: "#ffff",
            backgroundColor: "#175376",
            "&:hover": {
              backgroundColor: "#175376", // Prevent background color change on hover
            },
          }}
          onClick={handleCreate}
        >
          Add
        </Button>
        {selectionModel.length > 0 && (
          <MdDeleteSweep
            style={{ width: "25px", height: "25px", color: "red" }}
            onClick={handleDelete}
          />
        )}
      </Box>

      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns}
          pageSizeOptions={[5, 10]}
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
        />
      </div>

      {/* <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row-reverse",
          gap: "10px",
        }}
      >
        <FaPlus
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            color: "white",
            backgroundColor: "#1976D2",
            padding: "5px",
            cursor: "pointer",
          }}
          onClick={handleCreate}
        />
      </Box> */}

      <AddTimeDrawer
        open={drawerOpen}
        onClose={handleClose}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <UpdateTimeDrawer
        open={drawerUpdateOpen}
        onClose={handleClose}
        refresh={refresh}
        setRefresh={setRefresh}
        timeData={rowDataForEdit}
      />
    </>
  );
}
