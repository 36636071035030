export const getChipColor = (tagName) => {
    // Simple hash function to generate a number from a string
    const hashCode = (str) => {
      return str.split("").reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
    };
  
    // Convert the hash code to a hex color
    const intToRGB = (hash) => {
      const r = (hash >> 16) & 0xff;
      const g = (hash >> 8) & 0xff;
      const b = hash & 0xff;
      return `rgb(${r},${g},${b},0.7)`;
    };
  
    // Generate the color
    const hash = hashCode(tagName);
    const color = intToRGB(hash);
  
    return color;
  };


  export const generateAvatarColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 75%, 60%)`;
    return color;
  };
  
  export const getInitials = (name) => {
    const names = name.split(" ");
    const initials = names.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };

  // Helper function to get the background color based on the status
  export const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "Open":
        return "rgba(50, 213, 131, 1)"; // Green
      case "In Progress":
        return "rgba(54, 191, 250, 1)"; // Blue
      case "To be Tested":
        return "rgba(182, 146, 246, 1)"; // Purple
      case "On Hold":
        return "rgba(112, 83, 80, 0.5)"; // Brown (with opacity)
      case "In Review":
        return "rgba(253, 176, 34, 1)"; // Orange
      case "Closed":
        return "rgba(239, 83, 80, 1)"; // Red
      default:
        return "rgba(50, 213, 131, 1)"; // Default to Green (Open)
    }
  };