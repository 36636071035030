import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
  Popover,
  IconButton,
  Drawer,
  FormControl,
  InputAdornment,
  Avatar,
} from "@mui/material";
import Cookies from "js-cookie";
import { MdDeleteSweep } from "react-icons/md";
import { FaExclamation, FaPlus } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { MyContext } from "../../../ContextApi/MyContext";
import AddIssueDrawer from "./AddIssueDrawer";
import UpdateIssueDrawer from "./UpdateIssueDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { CiSearch } from "react-icons/ci";
import {
  generateAvatarColor,
  getInitials,
  getStatusBackgroundColor,
} from "../../../../../Constrant/constants";

export default function Issues({ refresh, setRefresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const userid = userInfo.id;

  const url = useParams();
  const projectName = url.projectname;
  const projectid = url.projectid;

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerUpdateOpen, setDrawerUpdateOpen] = useState(false);

  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/issue/get`, {
        companyid,
        projectid: projectid,
        userid,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        setLoading(false);
        console.log(res.data, "get all Issue");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/issue/delete`, {
          ids: selectionModel,
        })
        .then(() => {
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerUpdateOpen(true);
    }
  };

  const handleClose = () => {
    setDrawerUpdateOpen(false);
    setDrawerOpen(false);
    setRowDataForEdit(null);
  };

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://pms.vliv.app/issue/status`, { id, status: newStatus })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateIssue = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const openPopover = Boolean(anchorEl);

  const columns = [
    {
      field: "issuename",
      minWidth: 500,
      headerName: "Issue Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "percentage",
      headerName: "%",
      flex: 0.5,
      minWidth: 10,
      renderCell:(params)=>{
        return(
          <Box >
            <Box style={{background:"#D1FADF", padding:"5px", borderRadius:"10px"}}>
              <Typography sx={{fontWeight:"550"}} fullWidth>
              {params.row.percentage} %
              </Typography>
            </Box>
          
          </Box>
        )
      },
      sortable: true,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        const owners = params.row.owner || [];
        const firstOwner = owners[0] || "-"; // Fallback if no owners exist
        const additionalOwnersCount = owners.length - 1;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Placeholder avatar with the first letter of the owner's name */}
            <Avatar
              sx={{
                bgcolor: generateAvatarColor(firstOwner),
                fontSize: "10px",
                height: "28px",
                width: "28px",
                marginRight: "3px",
              }}
            >
              {getInitials(firstOwner)}
            </Avatar>
            {/* Owner's name */}
            <Box>{firstOwner}</Box>
            {/* If there are more owners, display "+X" inside a circular box */}
            {additionalOwnersCount > 0 && (
              <Box
                height={"28px"}
                width={"28px"}
                fontSize={"14px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  marginLeft: "6px",
                  color: "rgba(55, 102, 143, 1)",
                  border: "1px dashed rgba(55, 102, 143, 1)",
                  padding: "4px",
                  borderRadius: "50%",
                }}
              >
                +{additionalOwnersCount}
              </Box>
            )}
          </div>
        );
      },
    },
  
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: true,
    },
    // {
    //   field: "modifiedby",
    //   headerName: "Created By",
    //   flex: 0.5,
    //   sortable: true,
    // },
    {
      field: "priority",
      headerName: "Priority",
       minWidth: 150,
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        let color;
        switch (params.row.priority) {
          case "Low":
            color = "green";
            break;
          case "High":
            color = "red";
            break;
          default:
            color = "orange";
            break;
        }

        return (
          <Box display="flex" alignItems="center" sx={{marginTop:1.5}}>
            <FaExclamation style={{ color, marginRight: "5px" }} />
            <Typography style={{ color }}>{params.row.priority}</Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <select
          value={params.row.status || "Open"}
          onChange={(e) => {
            e.stopPropagation();
            handleStatusChange(params.row.id, e.target.value);
          }}
          style={{
            width: "100%",
            padding: "6px",
            borderRadius: "20px",
            border: "none",
            backgroundColor: getStatusBackgroundColor(params.row.status),
            color: "#fff",
            appearance: "none", // This hides the default arrow in most browsers
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Open"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Open"
          >
            Open
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Progress"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Progress"
          >
            In Progress
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("To be Tested"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="To be Tested"
          >
            To be Tested
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("On Hold"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="On Hold"
          >
            On Hold
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Review"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Review"
          >
            In Review
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Closed"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Closed"
          >
            Closed
          </option>
        </select>
      ),
    },
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  return (
    <>
      <CssBaseline />
      <Typography variant="h6" gutterBottom>
        Issue
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          sx={{ background: "#37668F" }}
          onClick={handleCreateIssue}
        >
          Add Issue
        </Button>

        <FormControl>
          <TextField
            size="small"
            variant="outlined"
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CiSearch />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {/* 
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ marginBottom: 2, width: 400 }}
          onChange={(e) => setSearchValue(e.target.value)}
        /> */}
        {selectionModel.length > 0 && (
          <MdDeleteSweep
            style={{ width: "25px", height: "25px", color: "red" }}
            onClick={handleDelete}
          />
        )}
      </Box>

      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
        />
      </div>

      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <AddIssueDrawer
          open={drawerOpen}
          onClose={handleClose}
          issueData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>
      <Drawer anchor="right" open={drawerUpdateOpen} onClose={handleClose}>
        <UpdateIssueDrawer
          open={drawerUpdateOpen}
          onClose={handleClose}
          issueData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>
    </>
  );
}
