import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  Select as MUISelect,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { fileSizeManager } from "../../../../../Constrant/fileSizeManager";
import ReactQuill from "react-quill";

export default function UpdateIssueDrawer({
  onClose,
  refresh,
  setRefresh,
  issueData,
}) {
  const url = useParams();
  const projectName = url.projectname;
  const projectId = url.projectid;

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const companyemail = userInfo.companyemail;
  const companyname = userInfo.companyname;
  const username = userInfo.username;
  const userid = userInfo.id;
  const useremail = userInfo.email;

  const [data, setData] = useState({
    issuename: "",
    projectid: projectId,
    milestoneid: null,
    taskid: null,
    subtaskid: null,
    owner: [],
    billingtype: "",
    status: "Open",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    priority: "",
    remainder: "",
    tags: [],
    file: "",
    note: "",
    modifiedby: username,
    companyid,
    companyemail,
    userid,
    workhours: "",
  });
  const [milestoneData, setMilestoneData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [subtaskData, setSubaskData] = useState([]);

  const [ownerOptions, setOwnerOptions] = useState([]);
  const [milestoneValue, setMilestoneValue] = useState("");
  const [taskValue, setTaskValue] = useState("");
  const [subtaskValue, setSubtaskValue] = useState("");

  const [fromTimeValue, setFromTimeValue] = useState(dayjs("2022-04-17T24:00"));
  const [toTimeValue, setToTimeValue] = useState(dayjs("2022-04-17T24:00"));

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (issueData) {
      setData({
        id: issueData.id,
        issuename: issueData.issuename || "",
        projectid: issueData.projectid || projectId,
        milestoneid: issueData.milestoneid || null,
        taskid: issueData.taskid || null,
        subtaskid: issueData.subtaskid || null,
        owner:
          issueData.owner?.map((owner) => ({
            value: owner,
            label: owner,
          })) || [],
        billingtype: issueData.billingtype || "",
        status: issueData.status || "Open",
        startdate: issueData.startdate || "",
        enddate: issueData.enddate || "",
        starttime: issueData.starttime || "",
        endtime: issueData.endtime || "",
        priority: issueData.priority || "",
        remainder: issueData.remainder || "",
        tags: issueData.tags || [],
        file: issueData.file || "",
        note: issueData.note || "",
        modifiedby: username,
        companyid,
        companyemail,
        userid,
        workhours: issueData.workhours || "",
      });
      setToTimeValue(dayjs(issueData.starttime));
      setFromTimeValue(dayjs(issueData.endtime));

      // Fetch Milestone, Task, and Subtask based on IDs from issueData
      if (issueData.milestoneid) {
        axios
          .post(`https://pms.vliv.app/milestone/getbyid`, {
            id: issueData.milestoneid,
          })
          .then((res) => {
            setMilestoneValue(res.data); // Prefill Milestone Dropdown

            console.log(res.data, "pre fill milestone data");

            axios
              .post(`https://pms.vliv.app/task/getbymilestoneid`, {
                milestoneid: issueData.milestoneid,
              })
              .then((taskRes) => {
                setTaskData(taskRes.data);
                if (issueData.taskid) {
                  const selectedTask = taskRes.data.find(
                    (task) => task.id === issueData.taskid
                  );
                  setTaskValue(selectedTask);

                  console.log(selectedTask, "selected task");

                  axios
                    .post(`https://pms.vliv.app/subtask/getbytaskid`, {
                      taskid: issueData.taskid,
                    })
                    .then((subtaskRes) => {
                      setSubaskData(subtaskRes.data);
                      if (issueData.subtaskid) {
                        const selectedSubtask = subtaskRes.data.find(
                          (subtask) => subtask.id === issueData.subtaskid
                        );
                        setSubtaskValue(selectedSubtask);
                      }
                    });
                }
              });
          })
          .catch((err) => console.log(err));
      }
    }
  }, [issueData, projectName, companyid]);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/milestone/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        setMilestoneData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));

    // Fetch Users Data
    axios
      .post(`https://pms.vliv.app/pmsuser/getall`, { companyid })
      .then((res) => {
        setOwnerOptions(
          res.data.map((user) => ({
            value: user.userid,
            label: user.username,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [refresh, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // =============== Milestone dropdown ============
  const handleMilestoneInputChange = (e) => {
    setMilestoneValue(e.target.value);
    axios
      .post(`https://pms.vliv.app/task/getbymilestoneid`, {
        milestoneid: e.target.value.id,
      })
      .then((res) => {
        setTaskData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  };

  // =============== Task dropdown ============
  const handleTaskInputChange = (e) => {
    setTaskValue(e.target.value);

    axios
      .post(`https://pms.vliv.app/subtask/getbytaskid`, {
        taskid: e.target.value.id,
      })
      .then((res) => {
        setSubaskData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  };

  // =============== Subtask dropdown ============
  const handleSubtaskInputChange = (e) => {
    setSubtaskValue(e.target.value);
  };

  const handleOwnerChange = (selectedOptions) => {
    setData((prevData) => ({
      ...prevData,
      owner: selectedOptions,
    }));
  };

  const handleTagChange = (event, newValue) => {
    setData((prevData) => ({
      ...prevData,
      tags: newValue,
    }));
  };

  //   file upload onchange
  const handleUploadFile = (event) => {
    setUploading(true); // Set uploading state to true

    const file = event.target.files[0];
    // To convert file size in GB
    const fileSizeInGB = file.size / (1024 * 1024 * 1024); // Convert bytes to GB

    if (file.size > 10 * 1024 * 1024) {
      // 10MB in bytes
      alert("File size exceeds 10MB. Please upload a file smaller than 10MB.");
      setUploading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("https://pms.vliv.app/issue/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData({
          ...data,
          file: res.data.file,
        });

        setUploading(false); // Set uploading state to false

        // To store the file size inside storage table
        if (res.data.file) {
          fileSizeManager(fileSizeInGB, companyid, companyemail);
        }
      })
      .catch((err) => {
        console.log(err);
        setUploading(false); // Set uploading state to false even if there's an error
      });
  };

  const handleSave = async () => {
    try {
      const ownersArray = data.owner.map((owner) => owner.label);
      const requestData = {
        ...data,
        owner: ownersArray,
        milestoneid: (milestoneValue && milestoneValue.id) || null,
        taskid: (taskValue && taskValue.id) || null,
        subtaskid: (subtaskValue && subtaskValue.id) || null,
        starttime: fromTimeValue,
        endtime: toTimeValue,
      };

      const res = await axios.post(
        "https://pms.vliv.app/issue/update",
        requestData
      );

      if (res.data.message == "Issue updated successfully") {
        alert(res.data.message);
        onClose();
        setRefresh(!refresh);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error Updating task:", error);
    }
  };

  const handleQuillChange = (value, name) => {
    setData({ ...data, [name]: value });
  };

  return (
    <Box sx={{ width: 600 }}>
      <Typography variant="h6" sx={{ padding: 0.5, ml: 2 }}>
        Update Issue
      </Typography>
      <Divider />
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <label>Issue Name*</label>
          <TextField
            name="issuename"
            value={data.issuename}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <label>Project Name</label>
          <TextField
            name="projectname"
            value={projectName}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <label>Milestone*</label>
          <FormControl fullWidth>
            <MUISelect
              value={milestoneValue}
              onChange={handleMilestoneInputChange}
              renderValue={(selected) =>
                selected?.milestone || "Select Milestone"
              }
            >
              <MenuItem value="">Select</MenuItem>
              {milestoneData.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.milestone} {/* Display milestone name */}
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label>Task</label>
          <FormControl fullWidth>
            <MUISelect value={taskValue} onChange={handleTaskInputChange}>
              <MenuItem>Select</MenuItem>
              {taskData.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.task}
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label>Subtask</label>
          <FormControl fullWidth>
            <MUISelect value={subtaskValue} onChange={handleSubtaskInputChange}>
              <MenuItem>Select</MenuItem>
              {subtaskData.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.subtask}
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <p className="form-label">Owner*</p>
          <ReactSelect
            isMulti
            options={ownerOptions}
            value={data.owner}
            onChange={handleOwnerChange}
            placeholder="Select Owner(s)"
          />
        </Grid>
        <Grid item xs={6}>
          <label>Billing Type</label>
          <FormControl fullWidth>
            <MUISelect
              name="billingtype"
              value={data.billingtype}
              onChange={handleInputChange}
            >
              <MenuItem>Select</MenuItem>
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Billable">Billable</MenuItem>
              <MenuItem value="Non Billable">Non Billable</MenuItem>
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label>Start Date*</label>
          <TextField
            type="date"
            name="startdate"
            value={data.startdate}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <label>End Date*</label>
          <TextField
            type="date"
            name="enddate"
            value={data.enddate}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <label>From time</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                value={fromTimeValue}
                onChange={(newValue) => setFromTimeValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label>To time</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                value={toTimeValue}
                onChange={(newValue) => setToTimeValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid> */}

        <Grid item xs={12}>
          <label>Work Hours</label>
          <TextField
            type="time"
            name="workhours"
            value={data.workhours}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 2, // allows selecting time in HH:MM:SS format
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <label>Priority</label>
          <FormControl fullWidth>
            <MUISelect
              name="priority"
              value={data.priority}
              onChange={handleInputChange}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label>Remainder</label>
          <FormControl fullWidth>
            <InputLabel id="reminder-label">Remainder</InputLabel>
            <MUISelect
              labelId="reminder-label"
              name="remainder"
              value={data.remainder}
              onChange={handleInputChange}
              label="Reminder"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </MUISelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <label>Tags</label>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={data.tags}
            onChange={handleTagChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Add tags" />
            )}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <label>File</label>
          <TextField
            type="file"
            name="file"
            onChange={handleUploadFile}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 2, // allows selecting time in HH:MM:SS format
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <p className="form-label">Description</p>
          <ReactQuill
            theme="snow"
            value={data.note}
            onChange={(value) => handleQuillChange(value, "note")}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link"],
                [{ color: [] }, { background: [] }],
                [{ align: [] }], // Add alignment options here
              ],
            }}
            formats={[
              "header",
              "font",
              "list",
              "bullet",
              "bold",
              "italic",
              "underline",
              "link",
              "color",
              "background",
              "align", // Add 'align' to formats
            ]}
            style={{
              height: "150px",
              marginBottom: "20px",
              borderRadius: "5px",
              fontSize: "large",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
          padding: 2.5,
        }}
      >
        <Button variant="contained" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#37668F" }}
          onClick={handleSave}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
}
