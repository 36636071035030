import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Gantt, ViewMode } from "gantt-task-react";
import { ViewSwitcher } from "./ViewSwitcher";
import { transformApiDataToGanttTasks } from "./helper"; // Helper function
import "gantt-task-react/dist/index.css";
import "./GanttChart.css";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Switch,
  Divider,
} from "@mui/material";

// Group tasks by "createdby" and "project"
const groupTasksByUserAndProject = (tasks) => {
  const grouped = {};
  tasks.forEach((task) => {
    const user = task.createdby;
    const projectId = task.project || task.id;
    if (!grouped[user]) {
      grouped[user] = {};
    }
    if (!grouped[user][projectId]) {
      grouped[user][projectId] = {
        project: task.type === "project" ? task : null,
        tasks: [],
      };
    }
    if (task.type === "task") {
      grouped[user][projectId].tasks.push(task);
    } else if (task.type === "project") {
      grouped[user][projectId].project = task;
    }
  });
  return grouped;
};

const TaskListTable = ({ tasks, rowWidth, rowHeight, onExpanderClick }) => {
  const [expandedProjects, setExpandedProjects] = useState({});

  const toggleProjectExpand = (projectId) => {
    setExpandedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  return (
    <div style={{ borderRadius: "4px" }}>
      {tasks.map((item, i) => {
        const isProject = item.type === "project";
        const isExpanded = expandedProjects[item.id];

        return (
          <div key={item.id}>
            <div
              style={{
                height: rowHeight,
                width: rowWidth,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: isProject ? "pointer" : "auto",
                fontFamily: "Roboto, sans-serif",
                background: i % 2 === 0 ? "#fafafa" : "#f0f0f0",
                padding: "15px",
                paddingLeft: isProject ? 10 : 40,
                // borderBottom: "1px solid #ddd",
              }}
              onClick={() => isProject && toggleProjectExpand(item.id)}
            >
              <Typography
                variant="body1"
                style={{ margin: 0, fontWeight: isProject ? "bold" : "normal" }}
              >
                {isProject ? (isExpanded ? "v " : "> ") : ""}
                {item.name.length > 25
                  ? item.name.substring(0, 25) + "..."
                  : item.name}
              </Typography>
            </div>

            {isExpanded &&
              item.tasks &&
              item.tasks.map((task, index) => (
                <div
                  key={task.id}
                  style={{
                    height: rowHeight,
                    width: rowWidth,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: index % 2 === 0 ? "#f9f9f9" : "#e0e0e0",
                    padding: "10px 20px",
                    paddingLeft: 60,
                  }}
                >
                  <Typography variant="body2" style={{ margin: 0 }}>
                    {task.name}
                  </Typography>
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

const GanttComponent = () => {
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const url = useParams();
  const projectName = url.projectname;
  const [searchTerm, setSearchTerm] = useState("");



  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/task/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const transformedTasks = transformApiDataToGanttTasks(res.data);
          setTasks(transformedTasks);
        } else {
          setTasks([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const groupedTasks = groupTasksByUserAndProject(tasks);
  const users = Object.keys(groupedTasks);

  let columnWidth = 105;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task) => {
    const newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    if (window.confirm("Are you sure you want to delete " + task.name + "?")) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
  };

  const handleProgressChange = (task) => {
    const newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(newTasks);
  };


    // Filter users based on the search functionality
    const filteredUsers = users.filter((user) =>
      user.toLowerCase().includes(searchTerm.toLowerCase())
    );


  if (loading) {
    return <p>Loading tasks...</p>;
  }

  return (
    <Box className="Wrapper" sx={{ padding: "10px" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px", marginLeft:"20px" }}>
          <input
            type="text"
            placeholder="Search by Owner"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "10px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              width: "300px",
            }}
          />
        </Box>
      </Grid>
    </Grid>

      {/* Gantt Chart with filtered users */}
      <Box sx={{ display: "flex", width: "100%", padding: "20px" }}>
      <Grid container spacing={2}>
        {filteredUsers.map((user, index) => (
          <Grid item xs={12} key={user}>
            <Box
              elevation={3}
              sx={{
                padding: "10px",
                marginBottom: "20px",
                borderRadius: "8px",
                backgroundColor: "#fafafa",
              }}
            >
              <Grid container alignItems="center">
                {/* Gantt Chart Column */}
                <Grid item xs={12}>
                  {Object.values(groupedTasks[user]).map(({ project, tasks }, i) => (
                    <Gantt
                      key={project ? project.id : `no-project-${i}`}
                      tasks={[...(project ? [project] : []), ...tasks]}
                      viewMode={view}
                      listCellWidth={isChecked ? "250px" : ""}
                      columnWidth={columnWidth}
                      rowHeight={40}
                      barFill={60} // Customize bar fill percentage
                      barProgressColor={index % 2 === 0 ? "#f79009" : "#f79009"} 
                      barBackgroundColor={index % 2 === 0 ? "#0bafec" : "#f79009"} 
                      TaskListHeader={() => (
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor: "#f4f5f7",
                            borderRadius: "4px",
                            padding: "13px",
                          }}
                        >
                          <Typography
                            sx={{
                              flex: 1,
                              fontFamily: "Roboto, sans-serif",
                              fontWeight: "bold",
                              paddingLeft: 2,
                              display: "flex",
                              alignItems: "center",
                              width: "300px",
                            }}
                          >
                            Owner
                          </Typography>
                        </Box>
                      )}
                      TaskListTable={(props) => (
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor: "#ffff",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              flex: 1,
                              fontFamily: "Roboto, sans-serif",
                              paddingLeft: 2,
                              display: "flex",
                              alignItems: "center",
                              width: "150px",
                            }}
                          >
                            {user}
                          </Typography>
                        </Box>
                      )}
                    />
                  ))}
                </Grid>
              </Grid>
            </Box>
            <Divider />
          </Grid>
        ))}
      </Grid>
    </Box>
 
    </Box>
  );
};

export default GanttComponent;
