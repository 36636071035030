import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
  Popover,
  IconButton,
  Drawer,
} from "@mui/material";
import Cookies from "js-cookie";
import { MdDeleteSweep } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import AddTaskDrawer from "./AddTaskDrawer";
import UpdateTaskDrawer from "./UpdateTaskDrawer";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { MyContext } from "../../../ContextApi/MyContext";
import { getStatusBackgroundColor } from "../../../../../Constrant/constants";

export default function Tasks({ refresh, setRefresh, setIsOpenSubTask }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const url = useParams();
  const projectName = url.projectname;

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerUpdateOpen, setDrawerUpdateOpen] = useState(false);

  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { taskData, setTaskData } = useContext(MyContext);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/task/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        setLoading(false);
        console.log(res.data, "get all task");
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/task/delete`, {
          ids: selectionModel,
        })
        .then(() => {
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerUpdateOpen(true);
    }
  };

  const handleClose = () => {
    setDrawerUpdateOpen(false);
    setDrawerOpen(false);
    setRowDataForEdit(null);
  };

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://pms.vliv.app/task/status`, { id, status: newStatus })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateTask = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const openPopover = Boolean(anchorEl);

  // To open subtask
  const handleOpenSubtask = (e, item) => {
    e.stopPropagation();
    setIsOpenSubTask(true);
    // console.log(item, "item from open subtask function");
    setTaskData(item);
  };

  const columns = [
    {
      field: "task",
      // minWidth: 500,
      headerName: "Task Name",
      flex: 1,

      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1,alignItems:"center",justifyContent:"space-between" }}>
          <Typography>{params.row.task}</Typography>
          <Button
            value={params.row.status || "Open"}
            onClick={(e) => handleOpenSubtask(e, params.row)}
          >
            Open
          </Button>
        </Box>
      ),
      sortable: true,
    },
    {
      field: "percent",
      headerName: "%",
      flex: 0.5,
      sortable: true,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <select
          value={params.row.status || "Open"}
          onChange={(e) => {
            e.stopPropagation();
            handleStatusChange(params.row.id, e.target.value);
          }}
          style={{
            width: "100%",
            padding: "6px",
            borderRadius: "20px",
            border: "none",
            backgroundColor: getStatusBackgroundColor(params.row.status),
            color: "#fff",
            appearance: "none", // This hides the default arrow in most browsers
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Open"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Open"
          >
            Open
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Progress"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Progress"
          >
            In Progress
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("To be Tested"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="To be Tested"
          >
            To be Tested
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("On Hold"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="On Hold"
          >
            On Hold
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("In Review"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="In Review"
          >
            In Review
          </option>
          <option
            style={{
              backgroundColor: getStatusBackgroundColor("Closed"),
              color: "#fff",
              marginBottom: "2px",
            }}
            value="Closed"
          >
            Closed
          </option>
        </select>
      ),
    },
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "milestone",
      headerName: "Milestone",
      flex: 1,
      sortable: true,
    },
    {
      field: "projectname",
      headerName: "Project Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "overdue",
      headerName: "Overdue",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <Box
          sx={{
            color: params.row.overdue ? "red" : "green",
          }}
        >
          {dayjs().format("DD-MM-YYYY")}
        </Box>
      ),
    },
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  return (
    <>
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" gutterBottom>
            Tasks
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
          >
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              sx={{ marginBottom: 2, width: 400 }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {selectionModel.length > 0 && (
              <MdDeleteSweep
                style={{ width: "25px", height: "25px", color: "red" }}
                onClick={handleDelete}
              />
            )}
          </div>
        </Box>

        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10]}
            onRowClick={handleRowClick}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
          />
        </div>

        <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
          <AddTaskDrawer
            open={drawerOpen}
            onClose={handleClose}
            taskData={rowDataForEdit}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Drawer>
        <Drawer anchor="right" open={drawerUpdateOpen} onClose={handleClose}>
          <UpdateTaskDrawer
            open={drawerUpdateOpen}
            onClose={handleClose}
            taskData={rowDataForEdit}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Drawer>

        <Button
          sx={{
            background: "black",
            "&:hover": {
              background: "black",
            },
            color: "white",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
          onClick={handleOpenPopover}
        >
          <FaPlus style={{ width: "30px", height: "30px" }} />
        </Button>

        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{ marginBottom: "10px" }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              onClick={handleClosePopover}
            >
              <CloseIcon />
            </IconButton>
            <Button variant="contained" fullWidth onClick={handleCreateTask}>
              Create Task
            </Button>
          </Box>
        </Popover>
      </Box>
    </>
  );
}
