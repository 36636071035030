import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
  Popover,
  IconButton,
  Drawer,
} from "@mui/material";
import Cookies from "js-cookie";
import { MdDeleteSweep } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import AddMileDrawer from "./AddMileDrawer";
import UpdateMileDrawer from "./UpdateMileDrawer";

export default function Milestone({ refresh, setRefresh }) {
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const url = useParams();
  const projectName = url.projectname;

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerUpdateOpen, setDrawerUpdateOpen] = useState(false);

  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    axios
      .post(`https://pms.vliv.app/milestone/get`, {
        companyid,
        projectname: projectName,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        console.log(res.data, "milestone")
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      axios
        .post(`https://pms.vliv.app/milestone/delete`, {
          ids: selectionModel,
        })
        .then(() => {
          setRefresh(!refresh);
          setSelectionModel([]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRowClick = (params, event) => {
    if (
      event.target.tagName !== "SELECT" &&
      !event.target.closest(".MuiDataGrid-cell--withRenderer")
    ) {
      setRowDataForEdit(params.row);
      setDrawerUpdateOpen(true);
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setDrawerUpdateOpen(false);
  };

  const handleStatusChange = (id, newStatus) => {
    axios
      .post(`https://pms.vliv.app/milestone/status`, { id, status: newStatus })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreate = () => {
    setDrawerOpen(true);
    setRowDataForEdit(null);
    handleClosePopover();
  };

  const openPopover = Boolean(anchorEl);

  const columns = [
    {
      field: "milestone",
      headerName: "Milestone",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <select
          value={params.row.status || "Open"}
          onChange={(e) => {
            e.stopPropagation();
            handleStatusChange(params.row.id, e.target.value);
          }}
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            backgroundColor:
              params.row.status === "Closed"
                ? "red"
                : params.row.status === "Open"
                ? "green"
                : "orange",
          }}
        >
          <option value="Open">Open</option>
          <option value="In Progress">In Progress</option>
          <option value="To be Tested">To be Tested</option>
          <option value="On Hold">On Hold</option>
          <option value="In Review">In Review</option>
          <option value="Closed">Closed</option>
        </select>
      ),
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      sortable: true,
    },
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: true,
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: true,
    },

    {
      field: "projectname",
      headerName: "Project Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "overdue",
      headerName: "Overdue",
      flex: 1,
      sortable: true,
    },
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  return (
    <>
      <CssBaseline />
      <Typography variant="h6" gutterBottom>
        Milestone
      </Typography>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ marginBottom: 2, width: 400 }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {selectionModel.length > 0 && (
          <MdDeleteSweep
            style={{ width: "25px", height: "25px", color: "red" }}
            onClick={handleDelete}
          />
        )}
      </div>

      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
        />
      </div>

      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <AddMileDrawer
          open={drawerOpen}
          onClose={handleClose}
          mileData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>

      <Drawer anchor="right" open={drawerUpdateOpen} onClose={handleClose}>
        <UpdateMileDrawer
          open={drawerUpdateOpen}
          onClose={handleClose}
          mileData={rowDataForEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Drawer>

      <Button
        sx={{
          background: "black",
          "&:hover": {
            background: "black",
          },
          color: "white",
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
        onClick={handleOpenPopover}
      >
        <FaPlus style={{ width: "30px", height: "30px" }} />
      </Button>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ marginBottom: "10px" }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            mb: 2,
          }}
        >
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={handleClosePopover}
          >
            <CloseIcon />
          </IconButton>
          <Button variant="contained" fullWidth onClick={handleCreate}>
            Create Milestone
          </Button>
        </Box>
      </Popover>
    </>
  );
}
