// App.js or MainContent.js

import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../Constrant/PageNotFount";

import Cookies from "js-cookie";
import Auth from "../Components/Auth/Auth";
import axios from "axios";
import ProtectRoute from "./ProtectRoute";

import Projects from "../Components/AComponents/Projects/Projects";
import ProjectsTab from "../Components/AComponents/Projects/ProjectsTab/ProjectsTab";
import DetailedProjectTab from "../Components/AComponents/Projects/DetailedProjects/DetailedProjectTab";
import Billings from "../Components/AComponents/Settings/Billings/Billings";

const AllRoutes = () => {
  // const userInfo = JSON.parse(Cookies.get("userinfo"));
  // const userrole = userInfo.userrole;

  let userInfo = null;
  let userrole = null;
  let companyemail = null;
  // let planname = null;

  const userInfoCookie = Cookies.get("userinfo");

  if (userInfoCookie) {
    try {
      userInfo = JSON.parse(userInfoCookie);
      userrole = userInfo?.userrole;
      companyemail = userInfo?.companyemail;
      // planname = userInfo?.planname;

      // console.log(userInfo, "userinfo from all routes")
    } catch (error) {
      console.error("Error parsing userinfo cookie:", error);
    }
  }
  const plannameLocal = localStorage.getItem("planname") || "";
  const [planname, setPlanname] = React.useState(plannameLocal);

  React.useEffect(() => {
    axios
      .post(`https://pms.vliv.app/api/v1/plancheck`, { companyemail })
      .then((res) => {
        console.log(res.data.planname, "plan name resposnse");
        setPlanname(res.data.planname);
        localStorage.setItem("planname", res.data.planname);
      })
      .catch((err) => console.log(err));
  }, [companyemail]);

  return (
    <>
      <Routes>
        <Route exact path="/signin" element={<Auth />} />

        <Route exact path="/" element={<ProtectRoute />}>
          <Route exact path="/pms/projects" element={<ProjectsTab />} />
          <Route
            path="/pms/projects/:projectname/:projectid"
            element={<DetailedProjectTab />}
          />
          <Route path="/pms/billing" element={<Billings/>}/>

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
